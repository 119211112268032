import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { ColorsHelper } from 'src/app/shared/helpers/color.helper'
import { DateHelper } from 'src/app/shared/helpers/date.helper'
import { DatePickerModel } from 'src/app/shared/models/date-picker.model'

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnChanges{

  @Input() errorMsg = ''
  @Input() readonly = false
  @Input() dates: DatePickerModel | undefined
  @Input() label = 'schedule.datePicker.date'

  @Output() sharedDates: EventEmitter<DatePickerModel> = new EventEmitter()
  @Output() sharedValidType: EventEmitter<boolean> = new EventEmitter()

  date = ''
  startHour = ''
  endHour = ''
  erroDate = ''
  startHourError = ''
  endHourError = ''
  today = new Date().toISOString().split('T')[0]

  constructor(
    private colorsHelper: ColorsHelper,
    private dateHelper: DateHelper
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.dates?.currentValue && !this.startHourError && !this.endHourError && !this.errorMsg) {
      if(changes.dates?.currentValue?.startDate) {
        const startDate = new Date(changes.dates?.currentValue?.startDate)
        this.date = this.dateHelper.addMinutes(startDate, -180).toISOString().split('T')[0]
        this.startHour =
          changes.dates.currentValue.startDate.getHours().toString().padStart(2, '0') +
          changes.dates.currentValue.startDate.getMinutes().toString().padStart(2, '0')
        this.validator('startHour')
      }
      if(changes.dates?.currentValue?.endDate) {
        this.endHour =
          changes.dates.currentValue.endDate.getHours().toString().padStart(2, '0') +
          changes.dates.currentValue.endDate.getMinutes().toString().padStart(2, '0')
        this.validator('endHour')
      }
    }
  }

  validator(input: string) {
    if ((this as any)[input].length !== 4 && !this.readonly) {
      (this as any)[input + 'Error'] = 'invalidHour'
    } else {
      (this as any)[input + 'Error'] = ''
    }
    if (this.date === '' && !this.readonly) {
      this.erroDate = 'selectDate'
    } else if (isNaN(Date.parse(this.date)) && !this.readonly) {
      this.erroDate = 'invalidDate'
    } else {
      this.erroDate = ''
    }
    if (this.startHour.length === 4 && this.endHour.length === 4 && !this.readonly) {
      const startHour = this.dateHelper.setTimeOfISOStringDate(new Date().toISOString(), this.formatHour(this.startHour))
      const endHour = this.dateHelper.setTimeOfISOStringDate(new Date().toISOString(), this.formatHour(this.endHour))
      if(startHour > endHour) {
        this.startHourError = 'verifyHour'
        this.endHourError = 'verifyHour'
        this.errorMsg = 'startDateError'
      } else if(this.dateHelper.addMinutes(startHour, 30) > endHour) {
        this.startHourError = 'verifyHour'
        this.endHourError = 'verifyHour'
        this.errorMsg = 'minimalTimeError'
      } else {
        this.startHourError = ''
        this.endHourError = ''
        this.errorMsg = ''
      }
    }
    if (!this.startHourError && !this.endHourError && !this.errorMsg) {
      this.sharedValidType.emit(true)
    } else {
      this.sharedValidType.emit(false)
    }
  }

  emitDate() {
    if (!this.startHourError && !this.endHourError && !this.errorMsg) {
      const startDate = new Date(this.date + ' ' + this.formatHour(this.startHour))
      const endDate = new Date(this.date + ' ' + this.formatHour(this.endHour))
      this.sharedDates.emit({ startDate, endDate })
    }
  }

  getPrimaryColorWithAlpha() {
    return this.colorsHelper.addAlphaLayer(this.colorsHelper?.getCssVariableValue('var(--primary_color)'), 10)
  }

  formatHour(hour: string) {
    return hour.replace(/(\d{2})(\d{2})/, "$1:$2:00")
  }
}
