<div class="wrapper-loader {{ wrapperClass }}">
  <div
    class="loader {{ customClass }}"
    role="status"
    [ngStyle]="{
      'border-width': borderSize,
      height: size,
      width: size,
      'border-color': color
    }"
  >
    <span class="visually-hidden">{{ 'loading' | translate }}</span>
  </div>
</div>
