<button
  class="button button-{{ size }}"
  [id]="id || timeStamp"
  [ngStyle]="{
    'font-size': fontSize,
    'line-height': lineHeight,
    color: hover ? setHoverText() : textColor,
    'background-color': hover ? setHoverBg() : color,
    'border-color': hover ? setHover(borderColor || textColor) : borderColor || textColor,
    'border-style': borderStyle,
    'text-align': textAlign,
    width: width,
    height: height
  }"
  [ngClass]="{
    disabled: disabled,
    'readonly-button': readonly,
    loading: loading,
    block: block,
    'display-flex': flex,
    'align-items-center': alignCenter,
    'no-padding': noPadding,
    'button-as-link': buttonAsLink,
    rounded: rounded,
    'rounded-circle': roundedCircle,
    'fake-button': isFakeButton
  }"
  [type]="type || 'button'"
  (click)="executeFunction($event)"
  (mouseover)="hover = true; emitHover.emit(true)"
  (mouseleave)="hover = false; emitHover.emit(false)"
  [disabled]="disabled"
>
  <ng-content *ngIf="hideOnLoading ? !loading : true"></ng-content>
  <loader class="{{ hideOnLoading ? 'hide-on-loading' : '' }}" *ngIf="loading" size="1em"
          color="{{ textColor }}"></loader>
</button>
