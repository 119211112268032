import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent {

  @Input() style = {
    position: '',
    left: '',
    top: ''
  }

  @Output() contextMenuItemClick: EventEmitter<string> = new EventEmitter<string>()

  constructor() { }

}
