<sq-modal (modalClose)="closeModal()" [open]="open" modalSize="lg">
  <ng-template #headerModal>
    <h4 class="m-0">{{ 'categories.' + (selectedCategory.id ? 'selectSubcategory' : 'selectCategory') | translate }}</h4>
  </ng-template>
  <div id="modal-category" class="modal-category scrollbar">
    <div class="display-flex align-items-center mb-3" *ngIf="selectedCategory.id">
      {{ 'categories.selectedCategory' | translate }}: <b class="ml-2">{{selectedCategory.name}}</b>
      <sq-button
        [color]="'transparent'"
        [textColor]="'var(--primary_color)'"
        [borderColor]="'transparent'"
        [loading]="loading"
        (emitClick)="reset()"
      >
        {{ 'change' | translate }}
      </sq-button>
    </div>
    <div class="row">
      <sq-input
        class="col-24"
        label="{{ 'categories.' + (selectedCategory.id ? 'searchSubcategory' : 'search') | translate }}"
        placeholder="{{ 'categories.' + (selectedCategory.id ? 'searchSubcategory' : 'search') | translate }}"
        type="text"
        [name]="'search-consultant'"
        [id]="'search-consultant'"
        [(value)]="searchValue"
        (keyPressUp)="search($event)"
      ></sq-input>
    </div>
    <sq-loader *ngIf="loading" [color]="'var(--primary_color)'" class="display-block text-center align-self-center" size="big"></sq-loader>
    <sq-infinity-scroll
      *ngIf="categories.length && !loading"
      [length]="count"
      [hasMore]="hasMore"
      [loading]="loadingScroll"
      [loaderColor]="'var(--primary_color)'"
      [elementToScrollId]="'modal-category'"
      (scrolledEmitter)="nextPageCategories()"
    >
      <div class="card display-flex justify-content-space-between align-items-center" *ngFor="let category of categories">
        <p>{{category.name}}</p>
        <sq-button
          [color]="'var(--primary_color)'"
          [textColor]="'var(--white-html)'"
          [borderColor]="'var(--primary_color)'"
          [loading]="loading"
          (emitClick)="chooseCategory(category)"
        >
          {{ 'requesterConsultantModal.select' | translate }}
        </sq-button>
      </div>
    </sq-infinity-scroll>
    <div *ngIf="!count && !loading" class="report-empty">
      <sq-loader *ngIf="loading" [color]="'var(--primary_color)'" class="display-block text-center align-self-center" size="big"></sq-loader>
      <i *ngIf="!loading" class="fa-light fa-file-slash icon"></i>
      <h5 *ngIf="!loading">
        {{ 'categories.' + (selectedCategory.id ? 'noResultsSubcategory' : 'noResults') | translate }}
      </h5>
    </div>
  </div>
  <ng-template #footerModal>
    <sq-button
      [color]="'transparent'"
      [textColor]="'var(--primary_color)'"
      [borderColor]="'var(--primary_color)'"
      [loading]="loading"
      (emitClick)="closeModal()"
    >
      {{ 'close' | translate }}
    </sq-button>
  </ng-template>
</sq-modal>
