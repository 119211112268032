<div
  class="modal align-items-center {{ backdropClass }}"
  [id]="id"
  #modal
  [ngClass]="{
    open: open,
    'full-screen-mobile': fullScreenMobile
  }"
>
  <div class="modal-dialog modal-{{ modalSize }} {{ modalClass }}" [style]="{ 'max-width': maxWidth }">
    <div class="modal-content">
      <div
        class="modal-header"
        [ngClass]="{
          'without-header': !hasHeader
        }"
      >
        <ng-container *ngIf="headerTemplate">
          <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
        </ng-container>
        <button *ngIf="buttonClose" (click)="openChange.emit()" class="button-close modal-exit">
          <i class="far fa-times"></i>
        </button>
      </div>
      <div
        class="modal-body"
        [ngClass]="{
          scrollbar: addScrollBar
        }"
      >
        <ng-content></ng-content>
      </div>
      <div class="modal-footer" *ngIf="footerTemplate">
        <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
      </div>
    </div>
  </div>
</div>
