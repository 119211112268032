<div class="items-per-page">
  <select-sq
  [label]="'pagination.itemsPerPage' | translate"
  [value]="perPage"
  [options]="pageOptions"
  [color]="'var(--primary_color)'"
  (sharedValue)="onPageSizeChange($event)"
></select-sq>
</div>
<div class="pagination-controls">
  <p class="gray-dark text-center">{{ getRageLavel() }}</p>
  <ul class="pagination scrollbar">
    <li
      (click)="onPageChange(1)"
      [ngClass]="{
        disabled: readonly || currentPage < 2
      }"
    >
      <a href="javascript:void(0);">
        <i class="fa-regular fa-chevron-double-left"></i>
      </a>
    </li>
    <li
      (click)="onPageChange(currentPage - 1)"
      [ngClass]="{
        disabled: readonly || currentPage < 2
      }"
    >
      <a href="javascript:void(0);">
        <i class="fa-regular fa-chevron-left"></i>
      </a>
    </li>
    <li class="disabled" *ngIf="showDotMin()">
      <a href="javascript:void(0);" style="text-decoration: none">
        <i class="fa-regular fa-ellipsis-h"></i>
      </a>
    </li>
    <ng-container *ngFor="let item of pages">
      <li
        *ngIf="canShow(item)"
        (click)="onPageChange(item, item === parseInt(currentPage, 10))"
        [ngClass]="{
          active: item === parseInt(currentPage, 10),
          disabled: readonly
        }"
      >
        <a href="javascript:void(0);">{{ item }}</a>
      </li>
    </ng-container>
    <li class="disabled" *ngIf="showDotMax()">
      <a href="javascript:void(0);">
        <i class="fa-regular fa-ellipsis-h"></i>
      </a>
    </li>
    <li
      (click)="onPageChange(currentPage + 1)"
      [ngClass]="{
        disabled: readonly || currentPage === pages.length
      }"
    >
      <a href="javascript:void(0);">
        <i class="fa-regular fa-chevron-right"></i>
      </a>
    </li>
    <li
      (click)="onPageChange(pages.length)"
      [ngClass]="{
        disabled: readonly || currentPage === pages.length
      }"
    >
      <a href="javascript:void(0);">
        <i class="fa-regular fa-chevron-double-right"></i>
      </a>
    </li>
  </ul>
</div>
