/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error'
import packageInfo from '../../package.json'

export const environment = {
  name: `${packageInfo?.name}`,
  version: `${packageInfo?.version}-development`,
  env: 'development',
  langs: ['pt', 'en', 'es'],
  production: false,
  dashboardUrl: 'https://stg-natura-dashboard.squidit.com.br',
  naturaCommunityUrl: 'https://stg-natura.squidcommunity.com',
  apiRouting: {
    community: 'https://api-dev.squidcommunity.com'
  },
  firebase: {
    apiKey: 'AIzaSyAyjTcBby90OyyhwIhUHeLYKrtafocnNRk',
    authDomain: 'dashboard-natura.firebaseapp.com',
    projectId: 'dashboard-natura',
    storageBucket: 'dashboard-natura.appspot.com',
    messagingSenderId: '324735933320',
    appId: '1:324735933320:web:688f60ee38fe4f08c2f83d',
  },
  websocket: {
    reports: 'https://api-staging-natura-report-handler.squidcommunity.com'
  }
}
