import {
  AfterContentInit,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core'

@Component({
  selector: 'input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss'],
})
export class CheckboxComponent implements AfterContentInit, OnChanges {
  @Input() type = 'checkbox'
  @Input() name = ''
  @Input() value?: any
  @Input() id = ''
  @Input() checked = false
  @Input() disabled?: boolean
  @Input() readonly?: boolean
  @Input() required?: boolean
  @Input() colorText = 'white'
  @Input() colorBackground = 'green'
  @Input() hideInput = false
  @Input() block = false
  @Input() error = false
  @Input() toggle = false
  @Input() direction = 'left'
  @Input() toggleSwitch = false
  @Input() indeterminate = false

  @Output() sharedValue: EventEmitter<any> = new EventEmitter()

  @ContentChild('labelRight')
  labelRight: TemplateRef<any> | null = null

  @ContentChild('labelLeft')
  labelLeft: TemplateRef<any> | null = null

  thisChecked = false
  timeStamp = `random-id-${(1 + Date.now() + Math.random()).toString().replace('.', '')}`

  ngAfterContentInit(): void {
    this.thisChecked = this.checked
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('checked')) {
      this.thisChecked = this.checked
    }
  }

  change(event: any): void {
    if (!this.readonly && !this.disabled) {
      if (event.target.checked) {
        this.sharedValue.emit({
          value: this.value,
          checked: true,
        })
        this.thisChecked = true
      } else {
        this.sharedValue.emit({
          value: this.value,
          checked: false,
        })
        this.thisChecked = false
      }
    }
  }
}
