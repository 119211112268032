import { Component, AfterViewInit, Input, Output, EventEmitter, ElementRef } from '@angular/core'

@Component({
  selector: 'tab-sq',
  templateUrl: './tab-squid.component.html',
  styleUrls: ['./tab-squid.component.scss'],
})
export class TabSquidComponent implements AfterViewInit {
  @Input() active = false
  @Input() title = ''
  @Input() textColor = 'var(--text_color)'
  @Input() color = 'var(--background)'
  @Input() borderColor = 'transparent'
  @Input() margin = '0'
  @Input() disabled?: boolean
  @Input() customClass = ''
  @Input() badgeCount?: number = 0
  @Output() whenOpen: EventEmitter<any> = new EventEmitter()

  nativeElement: HTMLElement

  constructor(public element: ElementRef) {
    this.nativeElement = element.nativeElement
  }

  ngAfterViewInit() {
    this.nativeElement = this.element.nativeElement
  }
}
