<div id="date-hour-picker">
  <div class="date-piker">
    <span>{{ label | translate}}</span>
    <label (click)="!readonly && datePiker.showPicker()" [ngClass]="{
      'error': erroDate !== '',
      'readonly': readonly
    }">
      <input
        #datePiker
        type="date"
        [min]="today"
        [(ngModel)]="date"
        (change)="validator('date') ; emitDate()"
      >
      <i class="fa-light fa-calendar"></i>
      <span>{{ date ? (date | date: 'fullDate') : ('schedule.datePicker.selectDate' | translate) }}</span>
    </label>
    <div
      class="box-validation"
      [ngClass]="{'show': erroDate !== ''}">
      <i class="fa-solid fa-triangle-exclamation"></i>
      {{ 'schedule.datePicker.' + erroDate | translate }}
    </div>
  </div>
  <div class="display-grid">
    <div class="display-flex hour-piker">
      <input-sq
        [label]="'schedule.datePicker.startHour' | translate"
        [required]="!readonly"
        [mask]="'Hh:m0'"
        [validationMask]="'Hh:m0'"
        [externalError]="startHourError ? ('schedule.datePicker.' + startHourError | translate) : ''"
        [value]="startHour"
        [readonly]="readonly"
        (sharedValue)="startHour = $event ; validator('startHour') ; emitDate()"
      >
      </input-sq>
      <input-sq
        [label]="'schedule.datePicker.endHour' | translate"
        [required]="!readonly"
        [mask]="'Hh:m0'"
        [externalError]="endHourError ? ('schedule.datePicker.' + endHourError | translate) : ''"
        [value]="endHour"
        [readonly]="readonly"
        (sharedValue)="endHour = $event ; validator('endHour') ; emitDate()"
      >
      </input-sq>
    </div>
    <span *ngIf="errorMsg && !readonly" class="date-hour-erro" [ngStyle]="{'background-color': getPrimaryColorWithAlpha()}">
      <i class="fa-light fa-triangle-exclamation mr-2"></i>
      <p [innerHTML]="'schedule.datePicker.' + errorMsg | translate"></p>
    </span>
  </div>
</div>
