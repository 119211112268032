export class EditorSqImageProperties {
  private editor: any
  private i18n: any
  private dialog: any
  private editorModule: any
  private form!: HTMLElement
  private _image!: HTMLImageElement

  constructor(editor: any, editorModule: any) {
    this.editor = editor
    this.i18n = this.editor.i18n.bind(this.editor)
    this.editorModule = editorModule
  }

  get image(): HTMLImageElement {
    return this._image
  }

  set image(value: HTMLImageElement) {
    this._image = value
  }

  public open(): void | false {
    this.createModal()

    this.editor.e.fire('hidePopup')

    this.editorModule.Jodit.modules.Helpers.markOwner(this.editorModule.Jodit, this.dialog.container)

    this.updateValues()

    this.dialog.open().setModal(true).setPosition()

    return false
  }

  private createModal() {
    if (this.dialog) {
      return
    }
    const check = new this.editorModule.Jodit.modules.UIButton(this.editor)
    check.state.text = 'Apply'
    check.state.icon.name = 'ok'

    const remove = new this.editorModule.Jodit.modules.UIButton(this.editor)
    remove.state.text = 'Delete'
    remove.state.icon.name = 'bin'

    this.dialog = new this.editorModule.Jodit.modules.Dialog({
      fullsize: this.editor.o.fullsize,
      globalFullSize: this.editor.o.globalFullSize,
      theme: this.editor.o.theme,
      language: this.editor.o.language,
      minWidth: Math.min(400, screen.width),
      minHeight: 400,
    })

    this.dialog.setHeader(this.i18n('Image properties'))

    const mainForm = this.createForm()
    this.form = mainForm

    this.dialog.setContent(mainForm)

    remove.onAction(() => {
      this.editor.s.removeNode(this._image)
      this.dialog.close()
    })
    check.onAction(() => {
      this.onApply()
    })

    this.dialog.setFooter([remove, check])

    this.dialog.setSize(this.editor.o.image.dialogWidth)
  }

  private onApply(): void {
    const { imageTitle, imageAlt, imageLink, imageLinkOpenInNewTab } = this.editorModule.Jodit.modules.Helpers.refs(this.form)

    // Title
    this.editorModule.Jodit.modules.Helpers.attr(this._image, 'title', imageTitle.value || null)

    // Alt
    this.editorModule.Jodit.modules.Helpers.attr(this._image, 'alt', imageAlt.value || null)

    // Link
    let link = this.editorModule.Jodit.modules.Dom.closest(this._image, 'a', this.editor.editor)

    if (imageLink.value) {
      if (!link) {
        link = this.editorModule.Jodit.modules.Dom.wrap(this._image, 'a', this.editor.createInside)
      }

      this.editorModule.Jodit.modules.Helpers.attr(link, 'href', imageLink.value)

      this.editorModule.Jodit.modules.Helpers.attr(link, 'target', imageLinkOpenInNewTab.checked ? '_blank' : null)
    } else {
      if (link && link.parentNode) {
        link.parentNode.replaceChild(this._image, link)
      }
    }

    this.editor.setEditorValue()

    this.dialog.close()
  }

  private createForm(): HTMLElement {
    return this.editor.c.fromHTML(`
        <form class="jodit-properties">
          <div class="jodit-grid jodit-grid_xs-column">
            <div data-ref="mainContent" class="jodit_col-lg-3-5 jodit_col-xs-5-5">
              <div class="jodit-form__group">
                <label>${this.i18n('Title')}</label>
                <input data-ref="imageTitle" type="text" class="jodit-input"/>
              </div>
              <div class="jodit-form__group">
                <label>${this.i18n('Alternative')}</label>
                <input data-ref="imageAlt" type="text" class="jodit-input"/>
              </div>
              <div class="jodit-form__group">
                <label>${this.i18n('Link')}</label>
                <input data-ref="imageLink" type="text" class="jodit-input"/>
              </div>
              <div class="jodit-form__group">
                <label class="jodit_vertical_middle">
                  <input data-ref="imageLinkOpenInNewTab" type="checkbox" class="jodit-checkbox"/>
                  <span>${this.i18n('Open link in new tab')}</span>
			          </label>
              </div>
            </div>
          </div>
        </form>`)
  }

  private updateValues(): void {
    const { imageTitle, imageAlt, imageLink, imageLinkOpenInNewTab } = this.editorModule.Jodit.modules.Helpers.refs(this.form)

    const updateText = () => {
      imageTitle.value = this.editorModule.Jodit.modules.Helpers.attr(this.image, 'title') || ''

      imageAlt.value = this.editorModule.Jodit.modules.Helpers.attr(this.image, 'alt') || ''

      const a = this.editorModule.Jodit.modules.Dom.closest(this.image, 'a', this.editor.editor)

      if (a) {
        imageLink.value = this.editorModule.Jodit.modules.Helpers.attr(a, 'href') || ''

        imageLinkOpenInNewTab.checked = this.editorModule.Jodit.modules.Helpers.attr(a, 'target') === '_blank'
      } else {
        imageLink.value = ''
        imageLinkOpenInNewTab.checked = false
      }
    }
    updateText()
  }
}
