import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class DateHelper {

  weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

  getDayNumberByName(name: string): number {
    return this.weekDays.indexOf(name)
  }

  getFirstDayOfYear(year: number): Date {
    return new Date(year, 0, 1)
  }

  getWeekByDate(date: Date): number {
    const dayOfYear = (
      (date.valueOf() - this.getFirstDayOfYear(date.getFullYear()).valueOf() + 86400000) / 86400000
    )
    return Math.ceil(dayOfYear / 7)
  }

  getDateByWeekAndDayNumber(day: number, week: number, year: number): Date {
    const days = 1 + day + (week - 1) * 7 - this.getFirstDayOfYear(year).getDay()
    return new Date(year, 0, days)
  }

  getDateByDayNameAndDateOfWeek(date: Date, dayName: string) {
    return this.getDateByWeekAndDayNumber(
      this.getDayNumberByName(dayName),
      this.getWeekByDate(date),
      date.getFullYear()
    )
  }

  setTimeOfISOStringDate(date: string, time: string): Date {
    const dateStr = date.split('T')[0]
    return new Date(`${dateStr}T${time}.000Z`)
  }

  addMinutes(date: Date, minutes: number): Date {
    return new Date(date.setMinutes(date.getMinutes() + minutes))
  }

  addTimeZone(datetime: string, timeZone = 'America/Sao_Paulo') {
    return new Date(new Date(datetime).toLocaleString('en', {timeZone}))
  }

  incrementOrDecrementMonth(date: Date, qty: number): Date {
    return new Date(date.setMonth(date.getMonth() + qty))
  }

  numberToTime(number: number) {
    const hour = Math.floor(number)
    let decpart = number - hour
    const min = 1 / 60
    decpart = min * Math.round(decpart / min)
    const minute = Math.floor(decpart * 60) + ''
    return (hour < 10 ? '0' + hour : hour) + ':' + minute.padStart(2, '0')
  }

  toTimezoneOffset(date: Date, targetOffset = -180) {
    const localOffset = date.getTimezoneOffset()
    const timeDifference = (targetOffset + localOffset) * 60 * 1000
    return new Date(date.getTime() + timeDifference)
  }
}
