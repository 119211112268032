import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ScheduleTrainingModel } from 'src/app/shared/models/scheduled-training.model'

@Component({
  selector: 'in-approval-training',
  templateUrl: './in-approval-training.component.html',
  styleUrls: ['./in-approval-training.component.scss']
})
export class InApprovalTrainingComponent {

  @Input() training: ScheduleTrainingModel | undefined

  @Output() emitClickViewTraining: EventEmitter<string> = new EventEmitter()
  @Output() emitClickConfirmTraining: EventEmitter<string> = new EventEmitter()

}
