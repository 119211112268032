import { DateHelper } from 'src/app/shared/helpers/date.helper'

export class CalendarEvent {
  id?: string
  title?: string
  start?: Date
  end?: Date
  status?: string
  backgroundColor?: string
  borderColor?: string
  container?: string
  theme?: string
  rejectionReason?: string
  classNames?: Array<string >
  blockedDay?: string
  timeZone?: string
  extendedProps?: {
    status?: string,
    blockedDay?: string,
    theme?: string,
    rejectionReason?: string,
    timeZone?: string,
    trainingCreator?: string
    requesterConsultantId?: string
    responsibleScheduling?: string
  }
  trainingCreator?: string
  requesterConsultantId?: string
  responsibleScheduling?: string

  constructor(
    id: string,
    title?: string,
    start?: Date,
    end?: Date,
    status?: string,
    backgroundColor?: string,
    borderColor?: string,
    container?: string,
    theme?: string,
    rejectionReason?: string,
    classNames?: Array<string>,
    blockedDay?: string,
    timeZone?: string,
    extendedProps?: {
      status?: string,
      blockedDay?: string,
      theme?: string,
      rejectionReason?: string,
      timeZone?: string,
      trainingCreator?: string
      requesterConsultantId?: string
      responsibleScheduling?: string
    },
    trainingCreator?: string,
    requesterConsultantId?: string,
    responsibleScheduling?: string
  ) {
    const dateHelper = new DateHelper()
    this.id = id
    this.title = title
    this.start = start ?  dateHelper.toTimezoneOffset(start) : undefined
    this.end = end ? dateHelper.toTimezoneOffset(end) : undefined
    this.status = status
    this.backgroundColor = backgroundColor
    this.borderColor = borderColor
    this.container = container
    this.theme = theme
    this.rejectionReason = rejectionReason
    this.classNames = classNames
    this.blockedDay = blockedDay
    this.timeZone = timeZone
    this.extendedProps = extendedProps
    this.trainingCreator = trainingCreator
    this.requesterConsultantId = requesterConsultantId
    this.responsibleScheduling = responsibleScheduling
  }
}
