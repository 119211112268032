import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiResponse } from 'src/app/shared/models/api-response.model'
import { AuthTokenService } from 'src/app/shared/services/auth/auth-token.service'

@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor(
    public http: HttpClient,
    public authTokenService: AuthTokenService
  ) {
  }

  async getHeaders(bcp = '') {
    const httpOptions = {
      withCredentials: true,
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          ...(bcp && {'bcp': bcp})
        }),
    }
    const token = await this.authTokenService.getToken()
    if (token) {
      httpOptions.headers = httpOptions.headers.append('Authorization', `Bearer ${token}`)
    }
    return httpOptions
  }

  defaultApiJson(err?: any): ApiResponse<any> {
    return {
      status: err?.status || 500,
      response: {
        data: err?.error?.response || [{}],
      },
    }
  }
}
