<div
  id="event-card"
  [ngClass]="{
    'ready': eventInside?.extendedProps?.status === 'ready' ||
      eventInside?.extendedProps?.status === 'not_done' ||
      eventInside?.extendedProps?.status === 'processed',
    'in-approval': eventInside?.extendedProps?.status === 'in_approval',
    'rejected': eventInside?.extendedProps?.status === 'rejected',
    'block': eventInside?.extendedProps?.status === 'block',
    'awaiting-adjustment': eventInside?.extendedProps?.status === 'awaiting_adjustment',
    'requested': eventInside?.extendedProps?.status === 'requested',
    'no-show': ['no_show', 'canceled', 'processed', 'removed'].includes(eventInside?.extendedProps?.status || ''),
    'expand-card': expandCard
  }"
  (mouseenter)="mouserEnter.emit(eventInside?.id + (eventInside?.extendedProps?.blockedDay ?? ''))"
  (mouseleave)="mouserLeave.emit(eventInside?.id + (eventInside?.extendedProps?.blockedDay ?? ''))"
>
  <div class="event-card-small">
    <i class="far" [ngClass]="{
      'fa-exclamation-triangle': eventInside?.extendedProps?.status === 'in_approval',
      'fa-check': eventInside?.extendedProps?.status === 'ready' ||
        eventInside?.extendedProps?.status === 'not_done' ||
        eventInside?.extendedProps?.status === 'processed',
      'fa-times': ['no_show', 'canceled', 'rejected', 'removed'].includes(eventInside?.extendedProps?.status || ''),
      'fa-lock': eventInside?.extendedProps?.status === 'block',
      'fa-pen': eventInside?.extendedProps?.status === 'awaiting_adjustment',
      'fa-gears': eventInside?.extendedProps?.status === 'requested'
    }"></i>
    <span>{{eventInside?.start | date:'HH:mm'}} - {{eventInside?.end | date:'HH:mm'}}</span>
    <span *ngIf="eventInside?.extendedProps?.status !== 'requested'">{{eventInside?.title}}</span>
    <span *ngIf="eventInside?.extendedProps?.status === 'requested'">{{eventInside?.theme}}</span>
  </div>
  <div class="event-card-expanded">
    <span class="title" *ngIf="eventInside?.extendedProps?.status !== 'requested'">
      {{eventInside?.title}} {{eventInside?.extendedProps?.status !== 'block' ? ' - ' + eventInside?.extendedProps?.theme : ''}}
    </span>
    <span class="title" *ngIf="eventInside?.extendedProps?.status === 'requested'">
      {{ eventInside?.extendedProps?.theme }}
    </span>
    <span>
      <i class="fa-light fa-calendar-days"></i>
      {{eventInside?.start | date:'dd/MM/yyyy'}}
    </span>
    <span>
      <i class="fa-light fa-clock"></i>
      {{eventInside?.start | date:'HH:mm'}} - {{eventInside?.end | date:'HH:mm'}}
    </span>
    <span style="font-size: 0.86rem;">
      <i style="font-size: 1rem;" class="far" [ngClass]="{
        'fa-exclamation-triangle': eventInside?.extendedProps?.status === 'in_approval',
        'fa-check': eventInside?.extendedProps?.status === 'ready' ||
         eventInside?.extendedProps?.status === 'not_done'||
         eventInside?.extendedProps?.status === 'processed',
        'fa-times': ['no_show', 'canceled', 'rejected', 'removed'].includes(eventInside?.extendedProps?.status || ''),
        'fa-lock': eventInside?.extendedProps?.status === 'block',
        'fa-pen': eventInside?.extendedProps?.status === 'awaiting_adjustment',
        'fa-gears': eventInside?.extendedProps?.status === 'requested'
      }"></i>
      {{'schedule.eventCard.' + eventInside?.extendedProps?.status | translate}}
    </span>
    <span style="font-size: 0.86rem; white-space: break-spaces;" *ngIf="eventInside?.extendedProps?.status === 'rejected'">
      {{'schedule.eventCard.rejectionReason' | translate }}: {{eventInside?.extendedProps?.rejectionReason}}
    </span>
    <span
      *ngIf="eventInside?.extendedProps?.status === 'requested' || (eventInside?.extendedProps?.status === 'in_approval' && eventInside?.extendedProps?.trainingCreator === 'GNT')">
      GN: {{eventInside?.extendedProps?.responsibleScheduling}}
    </span>
  </div>
</div>
