import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiResponse } from 'src/app/shared/models/api-response.model'
import { MailSign } from 'src/app/shared/models/mail-sing'
import { environment } from 'src/environments/environment'
import { map } from 'rxjs'
import { MainService } from 'src/app/shared/services/main/main.service'
import { AuthTokenService } from './auth-token.service'

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends MainService {

  public url: string

  constructor(
    authService: AuthTokenService,
    http: HttpClient
  ) {
    super(http, authService)
    this.url = environment.apiRouting.community
  }

  async loginViaEmail(data: MailSign) {
    return this.http.post<ApiResponse<any>>(`${this.url}/auth/signup/natura/email`, data).pipe(
      map((resp: ApiResponse<any>) => resp?.response)
    )
  }

  async updateToken() {
    return this.http.post<ApiResponse<any>>(`${this.url}/auth/natura/dashboard/token-verification`, {}, { ...await this.getHeaders() }).pipe(
      map((resp: ApiResponse<any>) => resp?.response)
    )
  }
}
