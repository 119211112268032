<div #fullCalendar>
  <full-calendar
    *ngIf="calendarOptions"
    [options]="calendarOptions"
    [events]="events"
    (contextmenu)="onRightClick($event)"
    (pointerdown)="pointerdown = true"
    (pointerup)="pointerdown = false"
  >
    <ng-template #eventContent let-arg>
      <calendar-event-card
        [event]="arg.event"
        [expandCard]="!pointerdown"
        (mouserEnter)="mouserEnter($event)"
        (mouserLeave)="mouserLeave($event)"
      >
      </calendar-event-card>
    </ng-template>
  </full-calendar>
</div>
<context-menu
  *ngIf="showContextMenu"
  [style]="getRightClickMenuStyle()"
  (contextMenuItemClick)="viewEvent($event)"
>
</context-menu>
