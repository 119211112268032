import { Component, Input, OnInit, ElementRef, ContentChild, TemplateRef } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ColorsHelper } from 'src/app/shared/helpers/color.helper'
import { TranslateHelper } from 'src/app/shared/helpers/translate.helper'
import { ValidatorHelper } from 'src/app/shared/helpers/validator.helper'
import { InputSquidComponent } from 'src/app/shared/components/input-sq/input-sq.component'

@Component({
  selector: 'input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss'],
  providers: [ValidatorHelper, TranslateHelper],
})
export class InputFileComponent extends InputSquidComponent implements OnInit {
  @Input() color = 'var(--primary_color)'
  @Input() textColor = 'var(--secondary_color)'
  @Input() borderColor = 'var(--primary_color)'
  @Input() fontSize = '1rem'
  @Input() textAlign = 'center'
  @Input() lineHeight = '1.15rem'
  @Input() block = false
  @Input() loading = false
  @Input() fileType = '*.*'
  @Input() multiple = false
  @Input() noPadding?: boolean
  @Input() invertedHover = false
  @Input() override set value(v: any) {
    this.thisValue = v
  }

  @ContentChild('customContent', { static: true })
  customContent: TemplateRef<HTMLElement> | null = null

  hover = false
  override timeStamp = `random-id-${(1 + Date.now() + Math.random()).toString().replace('.', '')}`

  constructor(
    public override translate: TranslateService,
    public override validatorHelper: ValidatorHelper,
    public override translateHelper: TranslateHelper,
    public override element: ElementRef,
    public colorsHelper: ColorsHelper
  ) {
    super(translate, validatorHelper, translateHelper, element)
  }

  override ngOnInit(): void {
    this.type = 'file'
  }

  override change(value: any): void {
    this.value = value.target.files
    this.validate()
    this.sharedValue.emit(value.target.files)
  }

  setHover(color: string): string {
    return this.colorsHelper?.lightenDarkenColor(this.colorsHelper?.getCssVariableValue(color), -25)
  }

  setHoverBg(): string {
    if (this.invertedHover) {
      return this.setHover(this.textColor)
    }
    return this.setHover(this.color)
  }

  setHoverText(): string {
    if (this.invertedHover) {
      return this.setHover(this.color)
    }
    return this.setHover(this.textColor)
  }
}
