<div
  class="wrapper-all-inside-input"
  [ngClass]="{
    'mb-3': marginBottom,
    'simple-select': simpleStyle
  }"
>
  <label
    *ngIf="label && label.length > 0"
    class="label"
    [ngClass]="{
      error: (externalError && externalError !== '') || (error && error !== ''),
      disabled: disabled,
      readonly: readonly,
      small: small
    }"
    [for]="id || timeStamp"
    [innerHtml]="label | safeHtml"
  ></label>
  <div
    class="no-padding wrapper-input wrapper-input-squid"
    [ngClass]="{
      error: (externalError && externalError !== '') || (error && error !== ''),
      disabled: disabled,
      readonly: readonly
    }"
  >
    <span class="input-group-text" [ngClass]="{ 'left-inside-label': labelType === 'leftInside' }" *ngIf="leftLabel">
      <ng-container *ngTemplateOutlet="leftLabel"></ng-container>
    </span>
    <select
      class="select"
      [ngClass]="{
        'no-label': !(label && label.length > 0),
        'has-icon': error || externalError,
        disabled: disabled,
        'full-label': fullLabel,
        small: small,
        borderless: borderLess,
        'left-inside-select': labelType === 'leftInside',
        'readonly': readonly
      }"
      [id]="id || timeStamp"
      [name]="name"
      [required]="required"
      [disabled]="disabled || readonly || loading"
      (blur)="validate(true)"
      [(ngModel)]="value"
      (ngModelChange)="change(select?.value)"
      #select
    >
      <option *ngIf="placeholder" value="" [selected]="value ? false : true" disabled>{{ placeholder }}</option>
      <option
        *ngFor="let option of options"
        [value]="option?.value"
        [selected]="value === option?.value ? true : false"
        [disabled]="!!option?.disabled"
      >
        {{ option?.label }}
      </option>
    </select>
    <span class="input-group-text" *ngIf="rightLabel">
      <ng-container *ngTemplateOutlet="rightLabel"></ng-container>
    </span>
  </div>
  <span
    class="icon icon-external"
    *ngIf="externalIcon"
    [ngClass]="{
      'no-label': !label || !label.length,
      small: small
    }"
    (click)="clickIcon($event)"
  >
    <ng-container *ngTemplateOutlet="externalIcon"></ng-container>
  </span>
  <div class="box-validation box-invalid show" *ngIf="errorSpan">
    <ng-container *ngIf="externalError || error">
      <i class="fa-solid fa-triangle-exclamation"></i>
    </ng-container>
    {{ externalError ? externalError : '' }}
    {{ error && !externalError ? error : '' }}
  </div>
</div>
