import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { AuthTokenService } from 'src/app/shared/services/auth/auth-token.service'
import { MainService } from 'src/app/shared/services/main/main.service'
import { environment } from 'src/environments/environment'
import * as localForage  from 'src/localforage'
import { NgxPermissionsService } from 'ngx-permissions'
import { ConsultantData } from 'src/app/shared/models/consultante-data'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class UserService extends MainService {
  public url: string
  public currentUserSubject = new BehaviorSubject<ConsultantData | null>(null)
  public localForage: any
  public user: ConsultantData | null = null
  constructor(http: HttpClient,
    authService: AuthTokenService,
    private permissionsService: NgxPermissionsService,
    public router: Router,
  ) {
    super(http, authService)
    this.url = environment.apiRouting.community
    this.localForage = localForage?.default
  }

  public async initUser() {
    try {
      this.user = await this.authTokenService.decodePayloadJWT()
      this.currentUserSubject.next(this.user)
      this.permissionsService.loadPermissions(this.user?.dashboard_permissions ?? [])
    } catch (error) {
      this.router.navigate(['/login'], {queryParams: {returnUrl: this.router.url}, queryParamsHandling: 'merge'})
    }
  }

  public async setUser(token: string): Promise<ConsultantData | boolean> {
    try {
      if (token) {
        await this.localForage.setItem('auth-token', token)
        this.user = await this.authTokenService.decodePayloadJWT()
        if (this.user) {
          this.permissionsService.loadPermissions(this.user?.dashboard_permissions ?? [])
          this.currentUserSubject.next(this.user)
        } else {
          this.router.navigate(['/login'], {queryParams: {returnUrl: this.router.url}, queryParamsHandling: 'merge'})
        }
      }
      return this.user || false
    } catch (error) {
      this.router.navigate(['/login'], {queryParams: {returnUrl: this.router.url}, queryParamsHandling: 'merge'})
      return false
    }
  }

  public get getUser(): ConsultantData | boolean {
    return this.currentUserSubject?.value || false
  }

  public async signOut(): Promise<void> {
    this.currentUserSubject.next(null)
    await this.localForage?.removeItem('auth-token')
    this.router.navigate(['/login'])
  }
}
