import { isPlatformServer } from '@angular/common'
import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  AfterViewInit,
  AfterContentChecked,
  OnDestroy,
  EventEmitter,
  Output,
  Inject,
  PLATFORM_ID,
  InjectionToken,
} from '@angular/core'

@Component({
  selector: 'infinity-scroll',
  templateUrl: './infinity-scroll.component.html',
  styleUrls: ['./infinity-scroll.component.scss'],
})
export class InfinityScrollComponent implements AfterViewInit, AfterContentChecked, OnDestroy {
  @ViewChild('scroll', { static: true }) scrollElement: ElementRef | undefined
  @Input() length = 0
  @Input() endMessage?: string
  @Input() hasMore?: boolean | string = true
  @Input() loaderColor?: string = 'var(--primary_color)'
  @Input() elementToScrollId?: string
  @Input() invertedScroll?: boolean = false

  @Output() scrolledEmitter: EventEmitter<any> = new EventEmitter()
  @Output() scrollIsOnTheStart = new EventEmitter<boolean>()

  elementToScroll: any
  scrollIsAtStart = true
  elementToScrollHeight = 1
  positionInScrollToEmitEvent = 0
  isServer = isPlatformServer(this.platformId)
  _loading = false

  constructor(@Inject(PLATFORM_ID) private platformId: InjectionToken<object>) {}

  @Input() set loading(value: boolean) {
    this._loading = value
    if (this.elementToScroll && this.invertedScroll && this.hasMore && this.elementToScroll.scrollTop === 0) {
      this.elementToScroll.scrollTop = this.elementToScrollHeight
    }
  }

  @Input() set setScrollAtStart(goToStart: boolean) {
    this.scrollAtStart()
  }

  ngAfterViewInit(): void {
    if (!this.isServer) {
      const { elementToScrollId } = this

      if (elementToScrollId) {
        this.elementToScroll = document.getElementById(elementToScrollId)
      }

      if (!elementToScrollId || !this.elementToScroll) {
        this.elementToScroll = window
      }
      this.elementToScroll.addEventListener('scroll', this.onScroll, false)

      if (this.elementToScroll && this.invertedScroll) {
        this.scrollAtStart()
      }
    }
  }

  ngAfterContentChecked(): void {
    if (!this.isServer) {
      if (this.elementToScrollId && this.elementToScroll && typeof this.elementToScroll.getAttribute === 'undefined') {
        const element = document.getElementById(this.elementToScrollId)
        if (element) {
          this.elementToScroll.removeEventListener('scroll', this.onScroll, false)
          element.addEventListener('scroll', this.onScroll, false)
          this.elementToScroll = element
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (!this.isServer) {
      this.elementToScroll.removeEventListener('scroll', this.onScroll, false)
    }
  }

  onScroll = () => {
    this.checkIfScrollIsOnTheStart()
    if (!this._loading && this.length > 0 && this.hasMore) {
      if (this.elementToScrollId) {
        this.scrollPositionToEmitEvent()
        /* Lógica quando o Scroll é feito no elemento passado pela prop */
        if (
          !this.invertedScroll &&
          this.elementToScroll.scrollTop >= this.elementToScroll.scrollHeight - this.positionInScrollToEmitEvent
        ) {
          this.eventEmitter()
        }

        /* Lógica quando o Scroll é feito no elemento passado pela prop com scroll invertido */
        if (this.invertedScroll && this.elementToScroll.scrollTop <= 10) {
          this.eventEmitter()
        }
      } else {
        /* Lógica quando o Scroll é feito na página em relação ao window do JavaScript */
        const elementHeight = this.elementToScroll.innerHeight
        const elementY = this.elementToScroll.scrollY
        if (elementHeight + elementY >= this.scrollElement?.nativeElement.offsetHeight + this.scrollElement?.nativeElement.offsetTop) {
          this.eventEmitter()
        }
      }
    }
  }

  private scrollPositionToEmitEvent(): number {
    if (this.positionInScrollToEmitEvent === 0) {
      this.elementToScrollHeight = this.elementToScroll.offsetHeight
      this.positionInScrollToEmitEvent = this.elementToScrollHeight + 20
      return this.positionInScrollToEmitEvent
    }
    return this.positionInScrollToEmitEvent
  }

  private scrollAtStart() {
    if (this.elementToScroll && this.invertedScroll) {
      this.elementToScroll.scrollTop = this.elementToScroll.scrollHeight - this.elementToScroll.offsetHeight
    } else if (this.elementToScroll && !this.invertedScroll) {
      this.elementToScroll.scrollTop = 0
    }
    this.scrollIsAtStart = true
    this.scrollIsOnTheStart.emit(this.scrollIsAtStart)
  }

  private eventEmitter() {
    this.elementToScroll.removeEventListener('scroll', this.onScroll, false)
    this.scrolledEmitter.emit()
    this.elementToScroll.addEventListener('scroll', this.onScroll, false)
  }

  private checkIfScrollIsOnTheStart() {
    if (this.elementToScroll && this.invertedScroll) {
      this.scrollIsAtStart = this.elementToScroll.scrollTop >= this.elementToScroll.scrollHeight - this.elementToScroll.offsetHeight - 20
      this.scrollIsOnTheStart.emit(this.scrollIsAtStart)
    } else if (this.elementToScroll && !this.invertedScroll) {
      this.scrollIsAtStart = this.elementToScroll.scrollTop === 0
      this.scrollIsOnTheStart.emit(this.scrollIsAtStart)
    }
  }
}
