import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MainService } from 'src/app/shared/services/main/main.service'
import { environment } from 'src/environments/environment'
import { Observable, from, map, switchMap } from 'rxjs'
import { ApiResponse } from 'src/app/shared/models/api-response.model'
import { ScheduleResponse } from 'src/app/shared/models/schedule-response'
import { ReportsResponse } from 'src/app/shared/models/reports-response'
import { UserConsultant } from 'src/app/shared/models/user-consultant'
import { AuthTokenService } from 'src/app/shared/services/auth/auth-token.service'
import { RegisterConsultantsModel } from 'src/app/shared/models/register-consultants.model'

@Injectable({
  providedIn: 'root',
})
export class ConsultantsService extends MainService {
  public url: string

  constructor(http: HttpClient, authService: AuthTokenService) {
    super(http, authService)
    this.url = environment.apiRouting.community
  }

  public getConsultantsList(
    page = 1,
    pageSize = 10,
    consultantType = 'cnt',
    cdOrNameOrEmail?: string,
    startDate?: string,
    endDate?: string
  ): Observable<ReportsResponse<UserConsultant>> {
    let qry = `?page=${page}&page_size=${pageSize}&consultant_type=${consultantType}`

    if (cdOrNameOrEmail) qry = qry + `&cd_or_name_or_email=${cdOrNameOrEmail}`
    if (startDate) qry = qry + `&start_date=${startDate}`
    if (endDate) qry = qry + `&end_date=${endDate}`

    return from(this.getHeaders()).pipe(
      switchMap(headers =>
        this.http.get<ApiResponse<ReportsResponse<UserConsultant>>>(`${this.url}/natura/consultants${qry}`, headers )
      ),
      map((resp: ApiResponse<ReportsResponse<UserConsultant>>) => resp.response?.data))
  }

  public async getConsultantTrainings(userId: string, startDate: string, endDate: string) {
    if (!userId) throw Error('userId required')
    if (!startDate) throw Error('startDate required')
    if (!endDate) throw Error('endDate required')
    const qry = `?start_date=${startDate}&end_date=${endDate}`

    return this.http
      .get<ApiResponse<ScheduleResponse>>(`${this.url}/natura/trainings/users/${userId}${qry}`, { ...(await this.getHeaders()) })
      .pipe(map((resp: ApiResponse<ScheduleResponse>) => resp.response?.data))
  }

  public async addConsultant(consultant: RegisterConsultantsModel) {
    return this.http
      .post<ApiResponse<ReportsResponse<RegisterConsultantsModel>>>(`${this.url}/natura/consultants`, consultant, {
        ...(await this.getHeaders()),
      })
      .pipe(map((resp: ApiResponse<ReportsResponse<RegisterConsultantsModel>>) => resp.response?.data))
  }

  public async addManyConsultant(consultantsList: Array<RegisterConsultantsModel>) {
    return this.http
      .post<ApiResponse<Array<RegisterConsultantsModel>>>(
        `${this.url}/natura/consultants/many`,
        { consultants: consultantsList },
        { ...(await this.getHeaders()) }
      )
      .pipe(map((resp: ApiResponse<Array<RegisterConsultantsModel>>) => resp.response?.data))
  }
}
