import { isPlatformServer, DOCUMENT } from '@angular/common'
import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  SimpleChanges,
  OnChanges,
  Inject,
  PLATFORM_ID,
  InjectionToken,
  HostListener,
} from '@angular/core'

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnChanges {
  @Input() open?: boolean
  @Input() modalSize: 'sm' | 'md' | 'lg' | string = 'md'
  @Input() modalClass?: string
  @Input() backdropClass?: string
  @Input() backdrop = 'static'
  @Input() buttonClose = true
  @Input() fullScreenMobile = false
  @Input() addScrollBar = false
  @Input() id = `random-id-${(1 + Date.now() + Math.random()).toString().replace('.', '')}`
  @Input() maxWidth = ''

  @Output() openChange: EventEmitter<any> = new EventEmitter()
  @Output() closeChange: EventEmitter<any> = new EventEmitter()
  @Output() leftPress: EventEmitter<any> = new EventEmitter()
  @Output() rightPress: EventEmitter<any> = new EventEmitter()

  @ViewChild('modal') modal: ElementRef | null = null

  @ContentChild('headerModal') headerTemplate?: TemplateRef<any> | null = null
  @ContentChild('footerModal') footerTemplate?: TemplateRef<any> | null = null

  modals: HTMLCollectionOf<Element> | undefined
  modalNumber = 0
  hasHeader = false
  isServer = isPlatformServer(this.platformId)
  document: any = null
  enableBackdropClick = false
  isMobile = window.innerWidth < 768

  constructor(@Inject(DOCUMENT) public documentImported: Document, @Inject(PLATFORM_ID) private platformId: InjectionToken<unknown>) {
    this.onKeydown = this.onKeydown.bind(this)
    this.document = documentImported || this.document
  }

  @HostListener('document:click', ['$event'])
  backdropClick(event: any) {
    if (this.backdrop === 'static' || !this.modal || !this.open || !this.enableBackdropClick) {
      return
    }
    const modalDialog = this.modal.nativeElement.firstElementChild
    if (!modalDialog?.contains(event.target)) {
      const body = this.document.getElementsByTagName('body')[0]
      const backdrop = this.document.getElementById('modal-backdrop') || this.document.createElement('div')
      this.openChange.emit(false)
      this.closeChange.emit()
      this.modal.nativeElement.style.display = 'none'
      if (backdrop.parentNode && this.modalNumber === 1) {
        backdrop.parentNode.removeChild(backdrop)
        body.classList.remove('block')
      }
      window.removeEventListener('keydown', this.onKeydown)
      this.enableBackdropClick = false
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('open')) {
      const body = this.document.getElementsByTagName('body')[0]
      const backdrop = this.document.getElementById('modal-backdrop') || this.document.createElement('div')
      if (this.open && this.modal) {
        this.hasHeader = !!this.headerTemplate
        this.modals = this.document.getElementsByClassName('modal open')
        body.classList.add('block')
        this.modal.nativeElement.style.display = 'flex'
        window.addEventListener('keydown', this.onKeydown)
        setTimeout(() => {
          this.modalNumber = this.modals?.length || 0
          if (this.modalNumber === 1) {
            backdrop.setAttribute('id', 'modal-backdrop')
            backdrop.setAttribute('class', 'modal-backdrop show')
            body.appendChild(backdrop)
          }
          this.enableBackdropClick = true
        })
      } else if (this.modal) {
        this.openChange.emit(false)
        this.closeChange.emit()
        this.modal.nativeElement.style.display = 'none'
        if (backdrop.parentNode && this.modalNumber === 1) {
          backdrop.parentNode.removeChild(backdrop)
          body.classList.remove('block')
        }
        this.enableBackdropClick = false
        window.removeEventListener('keydown', this.onKeydown)
      }
    }
  }

  onKeydown(event: any) {
    if (this.open) {
      this.modals = this.document.getElementsByClassName('modal')
      if (this.modals?.length === this.modalNumber) {
        this.events(event.keyCode)
      }
    }
  }

  events(key: number) {
    switch (key) {
      case 27:
        this.openChange.emit(false)
        this.closeChange.emit()
        break
      case 37:
        this.leftPress.emit()
        break
      case 39:
        this.rightPress.emit()
        break
    }
  }
}
