import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ToastHelper } from '@squidit/ngx-css'
import { Observable, Subject, catchError, debounceTime, filter, fromEvent, map, of, switchMap, takeUntil, tap } from 'rxjs'
import { CategoriesInterface } from 'src/app/shared/models/categories.interface'
import { CategoriesService } from 'src/app/shared/services/categories/categories.service'

@Component({
  selector: 'modal-select-category',
  templateUrl: './modal-select-category.component.html',
  styleUrls: ['./modal-select-category.component.scss']
})
export class ModalSelectCategoryComponent implements OnChanges {

  page = 1
  pageSize = 20
  categoryName = ''
  categories: Array<CategoriesInterface> = []
  count = 0
  hasMore = false
  loading = true
  loadingScroll = false
  selectedSubcategory: CategoriesInterface | null = null
  searchValue = ''
  private destroy$ = new Subject<void>()

  @Input() open = false
  @Input() selectedCategory: CategoriesInterface = {
    id: '',
    name: '',
    description: '',
    is_hidden: false,
    natura_trainings_category_id: ''
  }

  @Output() sharedCategory: EventEmitter<CategoriesInterface> = new EventEmitter()
  @Output() sharedSubcategory: EventEmitter<CategoriesInterface> = new EventEmitter()
  @Output() modalClose: EventEmitter<void> = new EventEmitter()

  constructor(
    public categoriesService: CategoriesService,
    public toastHelper: ToastHelper,
    public translate: TranslateService,
  ) { }

  ngOnChanges() {
    if (this.open) {
      const categories = this.selectedCategory.id ? this.fetchSubcategories() : this.fetchCategories()
      categories.subscribe(
        ({ count, content }) => {
          this.categories = content
          this.count = count
          this.hasMore = count > this.categories.length
          this.loading = false
        }
      )
    }
  }

  fetchCategories(): Observable<{ count : number , content: Array<CategoriesInterface> }> {
    return this.categoriesService.getCategories(this.categoryName, this.page, this.pageSize, false, false)
      .pipe(
        catchError(error => {
          this.displayError(error)
          return of({ count: 0, content: [] })
        })
      )
  }

  fetchSubcategories(): Observable<{ count : number , content: Array<CategoriesInterface> }> {
    return this.categoriesService.getSubcategories(this.selectedCategory.id || '', this.categoryName, false, false, this.page, this.pageSize)
      .pipe(
        catchError(error => {
          this.displayError(error)
          return of({ count: 0, content: [] })
        })
      )
  }

  nextPageCategories() {
    if (this.loadingScroll || !this.hasMore) {
      return
    }

    const categories = this.selectedCategory.id ? this.fetchSubcategories() : this.fetchCategories()

    this.loadingScroll = true
    this.page += 1
    categories.subscribe(({ count, content }) => {
      this.count = count
      this.categories = this.categories.concat(content)
      this.hasMore = this.count > this.categories.length
      this.loadingScroll = false
    })
  }

  search(event: any) {
    if (this.isIgnoredKey(event)) {
      return
    }

    fromEvent(event.target, 'keyup').pipe(
      debounceTime(500),
      filter((value: any) => this.categoryName !== value.target.value),
      map((value: any) => value.target.value),
      tap((value) => {
        this.categoryName = value
        this.page = 1
        this.loading = true
      }),
      switchMap(() => this.selectedCategory.id ? this.fetchSubcategories() : this.fetchCategories()),
      tap(() => this.loading = false),
      takeUntil(this.destroy$)
    ).subscribe(({ count, content }) => {
      this.count = count
      this.categories = content
      this.hasMore = this.count > this.categories.length
    })
  }

  closeModal() {
    this.categories = []
    this.count = 0
    this.modalClose.emit()
    this.open = false
    this.loading = true
  }

  reset() {
    this.categoryName = ''
    this.searchValue = ''
    this.page = 1
    this.loading = true
    this.selectedCategory = {
      id: '',
      name: '',
      description: '',
      is_hidden: false,
      natura_trainings_category_id: ''
    }
    this.selectedSubcategory = {
      id: '',
      name: '',
      description: '',
      is_hidden: false,
      natura_trainings_category_id: ''
    }
    this.fetchCategories().subscribe(({ count, content }) => {
      this.count = count
      this.categories = content
      this.hasMore = this.count > this.categories.length
      this.loading = false
    })
  }

  chooseCategory(category: CategoriesInterface) {
    this.categoryName = ''
    this.searchValue = ''
    this.page = 1
    this.loading = true
    if (this.selectedCategory.id) {
      this.selectedSubcategory = category
      this.sharedSubcategory.emit(this.selectedSubcategory)
      this.loading = false
    } else {
      this.selectedCategory = category
      this.sharedCategory.emit(this.selectedCategory)
    }
  }

  private isIgnoredKey(event: KeyboardEvent): boolean {
    const ignoredKeys = [
      'Shift',
      'Control',
      'Alt',
      'Meta',
      'ArrowRight',
      'ArrowLeft',
      'ArrowUp',
      'ArrowDown',
    ]

    return ignoredKeys.includes(event.key)
  }

  private displayError(error: any): void {
    const errorKey = `errorCode.${error?.error?.response?.error_id || error?.error?.response?.error_code}`
    this.translate.get(errorKey).subscribe(errorMSg => {
      if (errorKey === errorMSg) {
        this.toastHelper.toast.error(
          this.translate.get('error') + '<br>' + error?.error?.response?.error_code,
          { closeButton: true, position: 'top right' }
        )
      } else {
        this.toastHelper.toast.error(errorMSg, { closeButton: true, position: 'top right' })
      }
    })
  }
}
