import { Injectable, NgZone } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { Router } from '@angular/router'
import firebase from 'firebase/compat/app'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public angularFirestore: AngularFirestore,
    public angularFireAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone
  ) { }

  async login(email: string, password: string) {
    return new Promise((resolve, reject) => {
      this.angularFireAuth.signInWithEmailAndPassword(email, password).then(() =>
        this.angularFireAuth.authState.subscribe((user) => {
          if (user) {
            resolve(user)
          }
        })
      ).catch(
        (error) => reject(error)
      )
    })
  }

  public async currentUser(): Promise<firebase.User> {
    return new Promise((resolve) => {
      this.angularFireAuth.onAuthStateChanged((user) => {
        if (user) {
          resolve(user)
        } else {
          this.router.navigate(['/login'], {queryParams: {returnUrl: this.router.url}, queryParamsHandling: 'merge'})
        }
      })
    })
  }

  public async userTokenId(): Promise<string> {
    const user = await this.currentUser()
    return await user.getIdToken()
  }

  public async isLoggedIn(): Promise<boolean> {
    return new Promise((resolve) => {
      this.angularFireAuth.authState.subscribe((user) => {
        resolve(!!user)
        }
      )
    })
  }

  public async signOut(): Promise<void> {
    this.angularFireAuth.signOut().then(() => {
      this.router.navigate(['/login'], {queryParams: {returnUrl: this.router.url}, queryParamsHandling: 'merge'})
    })
  }

  public async sendEmailResetPassword(email: string) {
    return new Promise((resolve, reject) => {
      this.angularFireAuth.sendPasswordResetEmail(email).then(
        (res) => {
          resolve(res)
        }
      ).catch(
        (error) => reject(error)
      )
    })
  }

  public async confirmChangePassword(oobCode: string, password: string) {
    return new Promise((resolve, reject) => {
      this.angularFireAuth.confirmPasswordReset(oobCode, password).then(
        (res) => {
          resolve(res)
        }
      ).catch(
        (error) => reject(error)
      )
    })
  }
}
