<div *ngIf="loading" class="loading-auth">
  <loader class="display-flex text-center align-self-center" customClass="big"></loader>
  <p>{{ 'waitAuthenticating' | translate}}</p>
</div>
<router-outlet *ngIf="!loading"></router-outlet>

<div
  class="support-button"
  [tooltip]="'support' | translate"
  placement="left center"
  (click)="openSupportLink()"
>
  <i class="fa-brands fa-whatsapp"></i>
</div>
