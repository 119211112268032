import { Pipe, PipeTransform } from '@angular/core'
import { DateHelper } from 'src/app/shared/helpers/date.helper'

@Pipe({
  name: 'numberToTime'
})
export class NumberToTimePipe implements PipeTransform {

  constructor(private dateHelper: DateHelper) { }

  transform(value: number): string {
    return this.dateHelper.numberToTime(value)
  }

}
