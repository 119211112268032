import { HttpClient } from '@angular/common/http'
import { TranslateLoader } from '@ngx-translate/core'
import { forkJoin } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

/**
 * Responsible for generating translation files for different folders.
 * Used in SharedModule, which must be imported by all modules
 * that use shared components and translation features.
 */
export class MultiTranslateHttpLoader implements TranslateLoader {
  constructor(private http: HttpClient, public paths: Array<string>) {}

  public getTranslation(lang: string) {
    return forkJoin(this.paths.map((path) => this.http.get(`${path}/${lang}.json?cacheBuster=${environment.version}`))).pipe(
      map((response) => response.reduce((a, b) => Object.assign(a, b)))
    )
  }
}
