import { FormInputBase } from "./form-input-base"

export class ReportSearchModel {
  name: FormInputBase<string>
  date: FormInputBase<string>
  startDate: FormInputBase<string>
  endDate: FormInputBase<string>

  constructor(
    startDate = { value: '', readonly: false },
    endDate = { value: '', readonly: false },
    date = { value: '', readonly: false },
    name = { value: '', readonly: false },
  ) {
    this.startDate = startDate.value ? startDate : this.getDate(1, startDate.readonly)
    this.endDate = endDate.value ? endDate : this.getDate(0, endDate.readonly)
    this.date = date.value ? date : this.initDate(date.readonly)
    this.name = name
  }

  initDate(readonly: boolean) {
    return { value: this.getMonth(new Date()), readonly }
  }

  getDate(subtractMonth: number, readonly: boolean) {
    const date = new Date()
    return { value: new Date(date.setMonth(date.getMonth() - subtractMonth)).toISOString().split('T')[0], readonly }
  }

  getMonth(date: Date) {
    return `${date.toISOString().split('-')[0]}-` + `${date.toISOString().split('-')[1]}`
  }
}
