<sq-modal
  [open]="open"
  [modalSize]="'lg'"
  [modalClass]="'dashboard-modal'"
  (modalClose)="closeModalEvent()"
>
  <ng-template #headerModal>
    <h4 class="m-0"> {{ title | translate }} </h4>
  </ng-template>
  <div style="overflow: auto; height: 550px;">
    <div class="row search-form" style="position: sticky; top: 0;z-index: 2;">
      <div class="col-17 col-sm-20">
        <sq-input
          [label]='label | translate'
          [placeholder]='placeholder | translate'
          [value]="searchValue"
          [name]='"search-consultant"'
          [id]='"search-consultant"'
          (sharedKeyPress)="search($event)"
          (valueChange)="searchValue = $event"
        ></sq-input>
      </div>
      <div class="col-7 col-sm-4 display-flex">
        <sq-button
          style="min-width: 100%;"
          [size]="'lg'"
          color="primary"
          [invertedHover]="true"
          [block]="true"
          (emitClick)="resetSearch()"
        >
          {{ 'listConsultants.clean' | translate }}
        </sq-button>
      </div>
    </div>
    <div style="overflow: auto; height: 400px;" id="consultants-list-body" class="scrollbar">
      <loader *ngIf="loading" class="display-flex text-center align-self-center" customClass="big"></loader>
      <div *ngIf="!loading && consultants.length" class="consultant-list">
        <sq-infinity-scroll
          *ngIf="consultants.length && !loading"
          [length]="count"
          [hasMore]="hasMore"
          [loading]="loadingScroll"
          [elementToScrollId]="'consultants-list-body'"
          (scrolledEmitter)="nextPageConsultants()"
        >
        <div class="consultant-list">
          <div class="card consultant-card" *ngFor="let consultant of consultants">
            <div class="row">
              <div class="col-4 col-sm-3 col-md-2 display-flex align-items-center">
                <img src="{{consultant.photo ?? '/assets/avatar-bg.png'}}" alt="{{consultant.name}}">
              </div>
              <div class="col-17 col-sm-18 col-md-17  col-lg-18 consultant-info">
                <h6>{{consultant.name}}</h6>
                <span>{{ 'email' | translate}}: <b>{{consultant.email}}</b></span>
                <span>CN: <b>{{consultant.cn_code}}</b></span>
              </div>
              <div class="col-3 col-md-5  col-lg-4 display-flex align-items-center">
                <sq-button
                  style="min-width: 100%;"
                  [block]="true"
                  [size]="'lg'"
                  color="primary"
                  [invertedHover]="true"
                  (emitClick)="selectedConsultant.emit(consultant) ; open = false ; closeModal.emit()"
                >
                  <i class="fa-solid fa-check" [ngClass]="{'mr-2': window.innerWidth >= 768 }"></i>
                  {{ window.innerWidth >= 768 ? ('requesterConsultantModal.select' | translate) : '' }}
                </sq-button>
              </div>
            </div>
          </div>
        </div>
        </sq-infinity-scroll>
      </div>
      <div *ngIf="count === 0 && !loading" class="consultant-not-found">
        <i class="fa-regular fa-users-slash"></i>
        <h4>{{ 'listConsultants.consultantNotFound' | translate }}</h4>
      </div>
    </div>
  </div>
  <ng-template #footerModal>
    <footer>
      <sq-button
        color="primary"
        [invertedHover]="true"
        (emitClick)="closeModalEvent()"
      >
        {{ 'cancel' | translate }}
      </sq-button>
    </footer>
  </ng-template>
</sq-modal>
