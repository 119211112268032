import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs'
import { UserPermissions } from 'src/app/shared/enums/user-permissions.enum'
import { ToastHelper } from 'src/app/shared/helpers/toast.helper'
import { UserService } from 'src/app/shared/services/user/user.service'
import { MenuItem } from 'src/app/shared/models/menu-item.model'
import { AuthTokenService } from 'src/app/shared/services/auth/auth-token.service'
import { ConsultantData } from 'src/app/shared/models/consultante-data'
import * as localForage from 'src/localforage'

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {
  collapse = false
  collapseFixe = false
  window = window
  loading = false
  loadingLogout = false
  dashLogo = '/assets/img/logo-black.png'
  dashTitle = ''
  menu: Array<MenuItem> = []
  user: ConsultantData | null = null
  authenticateObservable!: Subscription
  localForage: any

  isDarkMode = false

  collapsedModule = ''

  constructor(
    public authTokenService: AuthTokenService,
    public router: Router,
    public userService: UserService,
    public toastHelper: ToastHelper,
    public translate: TranslateService,
    public activatedRoute: ActivatedRoute
  ) {
    this.localForage = localForage?.default
  }

  async ngOnInit() {
    const colorScheme = await this.localForage.getItem('color-scheme')

    if (colorScheme) {
      this.isDarkMode = colorScheme === 'dark'
    } else {
      this.isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches
    }

    this.dashLogo = this.isDarkMode ? '/assets/img/logo-white.png' : '/assets/img/logo-black.png'

    await this.userService.initUser()
    this.authenticateObservable = this.userService.currentUserSubject?.subscribe(async (user) => {
      if (user) {
        this.user = user
        this.loadMenu()
        this.loading = false
      } else {
        this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url }, queryParamsHandling: 'merge' })
      }
    })
  }

  ngOnDestroy(): void {
    this.authenticateObservable.unsubscribe()
  }

  loadMenu() {
    this.menu = [
      {
        module: 'reports',
        title: 'trainingsCarriedOut.title',
        url: '/reports/trainings-carried-out',
        permissions: [UserPermissions.Admin, UserPermissions.Trainer],
        icon: '<i class="fa-regular fa-list-ol"></i>',
      },
      {
        module: 'reports',
        title: 'valuationList.title',
        permissions: [UserPermissions.Admin],
        icon: '<i class="fa-regular fa-file-invoice-dollar"></i>',
        submenu: [
          {
            title: 'valuationList.title',
            url: '/reports/valuation',
            permissions: [UserPermissions.Admin],
          },
          {
            title: 'valuationList.acceptanceList',
            url: '/reports/valuation/processed',
            permissions: [UserPermissions.Admin],
          },
        ]
      },
      {
        module: 'reports',
        title: 'valuationList.title',
        url: `/reports/valuation/${this.user?.consultant_id}`,
        permissions: [UserPermissions.ReadValuation],
        icon: '<i class="fa-regular fa-file-invoice-dollar"></i>',
      },
      {
        module: 'consultants',
        title: 'listConsultants.consultants',
        permissions: [UserPermissions.Admin],
        icon: '<i class="fa-light fa-users-gear"></i>',
        submenu: [
          {
            title: 'registerConsultant.title',
            url: '/consultants/register',
            permissions: [UserPermissions.Sysadmin],
          },
          {
            title: 'listConsultants.title',
            url: '/consultants',
            permissions: [UserPermissions.Admin],
          },
        ],
      },
      {
        module: 'consultants',
        title: 'schedule.menu',
        url: `/consultants/schedule/${this.user?.user_id}`,
        permissions: [UserPermissions.Trainer],
        icon: '<i class="fa-light fa-calendar-days"></i>',
      },
      {
        module: 'reports',
        title: 'attendanceLits.title',
        url: '/reports/trainings-assessments',
        permissions: [UserPermissions.Admin, UserPermissions.Trainer],
        icon: '<i class="fa-regular fa-list-check"></i>',
      },
      {
        module: 'reports',
        title: 'trainingsList.title',
        url: '/reports/trainings-list',
        permissions: [UserPermissions.Admin, UserPermissions.Trainer],
        icon: '<i class="fa-regular fa-clipboard-list-check"></i>',
      },
      {
        module: 'manage-trainings',
        title: 'manageConsultantTrainings.title',
        url: `/manage-trainings`,
        permissions: [UserPermissions.Admin],
        icon: '<i class="fa-regular fa-calendars"></i>',
      },
      {
        module: 'admin',
        title: 'Dashboard Admin',
        permissions: [UserPermissions.Admin],
        icon: '<i class="fa-regular fa-gears"></i>',
        submenu: [
          {
            title: 'registerConsultant.title',
            url: '/admin/user-list',
            permissions: [UserPermissions.Sysadmin],
          },
          {
            title: 'categories.title',
            url: '/admin/categories',
            permissions: [UserPermissions.Admin],
          },
        ],
      },
    ]
    this.collapsedModule = window.location.pathname.split('/')[1]
  }

  async logout() {
    this.loadingLogout = true
    await this.userService.signOut()
  }

  colapseMenu(module: string) {
    if (this.collapsedModule === module) {
      this.collapsedModule = ''
    } else {
      this.collapsedModule = module
    }
  }

  async changeColorScheme() {
    this.isDarkMode = !this.isDarkMode
    let colorScheme = ''
    if (this.isDarkMode) {
      colorScheme = 'dark'
      this.dashLogo = '/assets/img/logo-white.png'
    } else {
      colorScheme = 'light'
      this.dashLogo = '/assets/img/logo-black.png'
    }
    document.querySelector('html')?.classList.remove(this.isDarkMode ? 'light' : 'dark')
    document.querySelector('html')?.classList.toggle(colorScheme)
    await this.localForage.setItem('color-scheme', colorScheme)
  }
}
