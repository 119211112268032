import { LOCALE_ID, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { TranslateHelper } from './shared/helpers/translate.helper'
import { HttpLoaderFactory, SharedModule } from './shared/shared.module'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { AngularFireModule } from '@angular/fire/compat'
import { AngularFireAuthModule } from '@angular/fire/compat/auth'
import { AngularFireStorageModule } from '@angular/fire/compat/storage'
import { AngularFirestoreModule } from '@angular/fire/compat/firestore'
import { AngularFireDatabaseModule } from '@angular/fire/compat/database'
import { environment } from 'src/environments/environment'
import { NgxPermissionsModule } from 'ngx-permissions'
import { FullCalendarModule } from '@fullcalendar/angular'
import { registerLocaleData } from '@angular/common'
import localePt from '@angular/common/locales/pt'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

registerLocaleData(localePt)

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    NgxPermissionsModule.forRoot(),
    FullCalendarModule,
    BrowserAnimationsModule,
    SharedModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' },],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private translate: TranslateHelper) {
    this.translate.setLanguageByBrowser()
  }
}
