import { Component, Input } from '@angular/core'

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input() size = ''
  @Input() borderSize = ''
  @Input() color = 'var(--primary_color)'
  @Input() customClass = ''
  @Input() wrapperClass = ''
}
