import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CalendarEvent } from 'src/app/shared/models/calendar-event.model'

@Component({
  selector: 'calendar-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent {

  eventInside: CalendarEvent | undefined

  @Input() expandCard = true
  @Input() set event(event: CalendarEvent) {
    this.eventInside = new CalendarEvent(
      event.id || '',
      event.title,
      event.start,
      event.end,
      event.status,
      event.backgroundColor,
      event.borderColor,
      event.container,
      event.theme,
      event.rejectionReason,
      event.classNames,
      event.blockedDay,
      event.timeZone,
      event.extendedProps
    )
  }

  @Output() mouserEnter: EventEmitter<string> = new EventEmitter()
  @Output() mouserLeave: EventEmitter<string> = new EventEmitter()
}
