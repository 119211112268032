<div class="wrapper-input-cover position-relative">
  <input-file
    #inputFile
    [id]="id"
    [name]="name"
    [required]="required"
    [disabled]="disabled || loading"
    [readonly]="disabled || loading"
    [fileType]="'image/png, image/jpeg, image/jpg'"
    [block]="true"
    (sharedValue)="change($event)"
    (sharedKeyPress)="keyDown($event)"
    [multiple]="multiple"
    class="input-banner"
  >
    <ng-template #customContent>
      <figure
        class="banner"
        [ngClass]="{
          default: !coverUrl
        }"
        [ngStyle]="{'height': !coverUrl ? height : '100%'}"
      >
        <div class="wrapper-text">
          <p *ngIf="inputMessage" class="message" [innerHTML]="inputMessage | translate | safeHtml"></p>
          <span *ngIf="!inputMessage && !coverUrl"><i class="fa-regular fa-file-image"></i> {{'inputCover.addImage' | translate }} </span>
        </div>
        <img
          src="{{ coverUrl || '' }}"
          alt="Cover Image"
          [ngStyle]="{'display': coverUrl ? '': 'none'}"
        />
        <div class="hover" *ngIf="!disabled">
          <i class="fa-regular fa-file-image"></i>
        </div>
      </figure>
    </ng-template>
  </input-file>
  <div
    class="wrapper-buttons"
    [ngClass]="{
      show: coverUrl
    }"
  >
    <button-sq
      *ngIf="!disabled"
      tooltip="{{ 'inputCover.coverDelete' | translate }}"
      class="box-shadow display-block rounded"
      (emitClick)="deleteImage()"
      [roundedCircle]="true"
      borderColor="var(--gray_dark)"
      textColor="var(--gray_dark)"
      color="var(--gray_light)"
    >
      <i class="fa-regular fa-trash"></i>
    </button-sq>
  </div>
</div>
