import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core'
import { ColorsHelper } from 'src/app/shared/helpers/color.helper'

@Component({
  selector: 'button-sq',
  templateUrl: './button-sq.component.html',
  styleUrls: ['./button-sq.component.scss'],
  providers: [ColorsHelper]
})
export class ButtonSqComponent {
  @Input() type = 'button'
  @Input() color = 'var(--primary_color)'
  @Input() textColor = 'var(--secondary_color)'
  @Input() textAlign = 'center'
  @Input() size = 'md'
  @Input() borderColor = 'var(--primary_color)'
  @Input() borderStyle = 'solid'
  @Input() fontSize = '1rem'
  @Input() lineHeight = '1.15rem'
  @Input() readonly?: boolean
  @Input() loading?: boolean
  @Input() disabled?: boolean
  @Input() block?: boolean
  @Input() flex?: boolean
  @Input() alignCenter?: boolean
  @Input() noPadding?: boolean
  @Input() buttonAsLink?: boolean
  @Input() hideOnLoading?: boolean
  @Input() invertedHover = false
  @Input() rounded = false
  @Input() roundedCircle = false
  @Input() isFakeButton = false
  @Input() id?: string
  @Input() width?: string
  @Input() height?: string

  @Output() emitClick: EventEmitter<any> = new EventEmitter()
  @Output() emitHover: EventEmitter<boolean> = new EventEmitter()

  nativeElement: ElementRef
  hover = false
  timeStamp = `random-id-${(1 + Date.now() + Math.random()).toString().replace('.', '')}`

  constructor(public element: ElementRef, public colorsHelper: ColorsHelper) {
    this.nativeElement = element.nativeElement
  }

  setHover(color: string): string {
    return this.colorsHelper?.lightenDarkenColor(this.colorsHelper?.getCssVariableValue(color), -25)
  }

  setHoverBg(): string {
    if (this.invertedHover) {
      return this.setHover(this.textColor)
    }
    return this.setHover(this.color)
  }

  setHoverText(): string {
    if (this.invertedHover) {
      return this.setHover(this.color !== 'transparent' ? this.color : 'var(--white-html)')
    }
    return this.setHover(this.textColor !== 'transparent' ? this.textColor : 'var(--white-html)')
  }

  executeFunction(event: any): void {
    const { loading, disabled, readonly, isFakeButton } = this
    if (!loading && !disabled && !readonly && !isFakeButton) {
      this.emitClick.emit(event)
    }
  }
}
