import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { catchError, Observable, throwError } from 'rxjs'
import { UserService } from 'src/app/shared/services/user/user.service'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

constructor(
  public userService: UserService
) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(catchError(err => {
      if ([401, 403, 409].includes(err.status)) {
          this.userService.signOut()
      }
      return throwError(() => err)
    }))
  }
}
