<div class="training-card card">
  <h4>
    {{ training?.name }} -
    {{ training?.theme }} -
    {{ training?.training_creator }} -
    {{ training?.scheduled_start_date_time | date:'dd/MM' }}
    {{ training?.scheduled_start_date_time | date:'HH' }}h{{ training?.scheduled_start_date_time | date:'mm' }}
  </h4>
  <div class="schdule">
    <span>
      <i class="fa-regular fa-calendar mr-3"></i>
      {{ training?.scheduled_start_date_time | date:'fullDate' }}
    </span>
    <span>
      <i class="fa-regular fa-clock mr-3"></i>
      {{ training?.scheduled_start_date_time | date:'HH' }}h{{ training?.scheduled_start_date_time | date:'mm' }} -
      {{ training?.scheduled_end_date_time | date:'HH' }}h{{ training?.scheduled_end_date_time | date:'mm' }}
    </span>
  </div>
  <div class="buttons">
    <button-sq
      [color]="'transparent'"
      [borderColor]="'transparent'"
      [textColor]="'var(--primary_color)'"
      [noPadding]="true"
      (emitClick)="emitClickViewTraining.emit(training?.id)"
    >
      {{ 'schedule.form.approveTraining.view' | translate }}
    </button-sq>
    <button-sq
      *ngIf="!training?.requester_consultant_id"
      [color]="'transparent'"
      [borderColor]="'var(--green)'"
      [textColor]="'var(--green)'"
      (emitClick)="emitClickConfirmTraining.emit(training?.id)"
    >
      <i class="fa-regular fa-check mr-3"></i> {{ 'schedule.form.approveTraining.approve' | translate }}
    </button-sq>
  </div>
</div>
