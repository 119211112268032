import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { TranslateHelper } from 'src/app/shared/helpers/translate.helper'
import { ValidatorHelper } from 'src/app/shared/helpers/validator.helper'

@Component({
  selector: 'input-sq',
  templateUrl: './input-sq.component.html',
  styleUrls: ['./input-sq.component.scss'],
  providers: [ValidatorHelper, TranslateHelper, TranslateService],
})
export class InputSquidComponent implements OnChanges, OnInit {
  @Input() name = ''
  @Input() id = ''
  @Input() label?: string
  @Input() customClass?: string
  @Input() disabled = false
  @Input() readonly = false
  @Input() small = false
  @Input() required = false
  @Input() autocomplete = ''
  @Input() placeholder?: string
  @Input() type = 'text'
  @Input() externalError?: any
  @Input() validateIcons = true
  @Input() hasTimeout = false
  @Input() errorSpan = true
  @Input() min?: number | string
  @Input() max?: number | string
  @Input() mask?: string
  @Input() thousandSeparator?: string
  @Input() maskSuffix?: string
  @Input() backgroundColor = 'var(--backgroundColor)'
  @Input() maxLength: number | string | any = ''
  @Input() minLength = 1
  @Input() marginBottom = true
  @Input() borderLess = false
  @Input() showLabelOptionalField = false
  @Input() disableEmptyInputIconClick = false
  @Input() displayMask?: Array<string>
  @Input() sanitizationMask?: string
  @Input() validationMask?: string

  @Input() hideIconOnLoading = false
  @ContentChild('externalIcon', { static: true })
  externalIcon: TemplateRef<HTMLElement> | null = null
  @ContentChild('leftLabel', { static: true })
  leftLabel: TemplateRef<HTMLElement> | null = null
  @ContentChild('rightLabel', { static: true })
  rightLabel: TemplateRef<HTMLElement> | null = null
  @Output() sharedValue: EventEmitter<any> = new EventEmitter()
  @Output() sharedKeyPress: EventEmitter<any> = new EventEmitter()
  @Output() sharedFocus: EventEmitter<any> = new EventEmitter()
  @Output() sharedValidType: EventEmitter<any> = new EventEmitter()
  @Output() emitExternalIconClick: EventEmitter<any> = new EventEmitter()
  @ViewChild('input')
  input: ElementRef | null = null
  error: any = false
  errorMask: any = false
  enableEventEmit = true
  timeoutInput: any
  timeOutInputTime = 800
  timeStamp = `random-id-${(1 + Date.now() + Math.random()).toString().replace('.', '')}`
  thisValue: any = ''
  nativeElement: ElementRef

  constructor(
    public translate: TranslateService,
    public validatorHelper: ValidatorHelper,
    public translateHelper: TranslateHelper,
    public element: ElementRef
  ) {
    this.nativeElement = element.nativeElement
    this.autocomplete = this.autocomplete || this.name
  }

  @Input() set value(v: any) {
    this.thisValue = v

    if (this.disableEmptyInputIconClick) {
      this.enableEventEmit = !!this.thisValue
    }
  }

  ngOnInit() {
    this.enableEventEmit = !this.disableEmptyInputIconClick
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value || changes.type) {
      if (this.type === 'number' && typeof this.value === 'number') {
        if (typeof this.value === 'object' && !this.value && this.value !== null) {
          this.value = 0
        }
        this.value = parseFloat(this.value)
        this.sharedValue.emit(this.value)
      }
    }
  }

  validate(isBlur = false) {
    if (isBlur) {
      this.sharedFocus.emit(false)
    }
    if (this.externalError) {
      this.error = false
    } else if (this.required && (!this.thisValue || this.thisValue.length < this.minLength) && this.thisValue !== 0) {
      this.sharedValidType.emit(false)
      if (this.minLength > 1) {
        this.translate
          .stream('formErrors.minLength')
          .subscribe((text) => {
            this.error = text
          })
          .unsubscribe()
      } else {
        this.translate
          .stream('formErrors.required')
          .subscribe((text) => {
            this.error = text
          })
          .unsubscribe()
      }
    } else if (this.type === 'text' && this.thisValue.match(/^ +$/)) {
      this.sharedValidType.emit(false)
      this.translate
        .stream('formErrors.invalidCharacter')
        .subscribe((text) => {
          this.error = text
        })
        .unsubscribe()
    } else if (this.type === 'email' && this.validateIcons && !this.validatorHelper.email(this.thisValue)) {
      this.sharedValidType.emit(false)
      this.translate
        .stream('formErrors.email')
        .subscribe((text) => {
          this.error = text
        })
        .unsubscribe()
    } else if (this.type === 'tel' && this.validateIcons && !this.validatorHelper.phone(this.thisValue)) {
      this.sharedValidType.emit(false)
      this.translate
        .stream('formErrors.phone')
        .subscribe((text) => {
          this.error = text
        })
        .unsubscribe()
    } else if (this.type === 'url' && this.thisValue && this.thisValue.length && !this.validatorHelper.url(this.thisValue)) {
      this.sharedValidType.emit(false)
      this.translate
        .stream('formErrors.url')
        .subscribe((text) => {
          this.error = text
        })
        .unsubscribe()
    } else if (this.errorMask) {
      this.sharedValidType.emit(false)
      this.translate
        .stream('formErrors.regex')
        .subscribe((text) => {
          this.error = text
        })
        .unsubscribe()
    } else {
      this.sharedValidType.emit(true)
      this.error = ''
    }
  }

  change(event: any): void {
    this.emit(event)
    this.validate()
  }

  emit(event: any) {
    if (this.hasTimeout) {
      this.thisValue = event
      this.timeoutInput = clearTimeout(this.timeoutInput)
      this.timeoutInput = setTimeout(() => {
        this.sharedValue.emit(event)
      }, this.timeOutInputTime)
    } else {
      this.thisValue = event
      this.sharedValue.emit(event)
    }
  }

  keyDown(event: any) {
    if (this.enableEventEmit) {
      this.sharedKeyPress.emit(event)
    }
  }

  clickIcon(event: any) {
    if (this.enableEventEmit) {
      this.emitExternalIconClick.emit(event)
    }
  }

  maskError(event: boolean) {
    this.errorMask = event
  }
}
