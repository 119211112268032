<div class="modal overlay align-items-center {{ backdropClass }} {{ overlayDirection }}" [id]="id" #overlay [ngClass]="{ open: open }">
  <div
    class="modal-dialog modal-xl {{ overlayClass }} {{ overlayDirection }} background-{{ backdropColor }}"
    [ngStyle]="{ 'background-color': headerColor }"
    [ngClass]="{ opened: finishOpening }"
  >
    <div class="modal-content scrollbar">
      <div
        class="modal-header background-{{ headerColor }}"
        [ngClass]="{
          'without-header': !hasHeader,
          borderless: borderless
        }"
        [ngStyle]="{
          'background-color': headerColor,
          height: headerHeight
        }"
      >
        <ng-container *ngIf="headerTemplate">
          <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
        </ng-container>

        <button *ngIf="showClose" (click)="openChange.emit()" class="button-close modal-exit">
          <i class="far fa-times"></i>
        </button>
      </div>

      <div
        class="modal-body background-{{ bodyColor }} scrollbar"
        [ngClass]="{
          'without-footer': !hasFooter,
          'display-flex align-items-center': contentCentralized
        }"
        [ngStyle]="{ 'background-color': bodyColor }"
      >
        <ng-content></ng-content>
      </div>

      <div
        class="modal-footer background-{{ footerColor }}"
        [ngStyle]="{ 'background-color': footerColor }"
        [ngClass]="{ borderless: borderless }"
        *ngIf="hasFooter && footerTemplate"
      >
        <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
      </div>
    </div>
  </div>
</div>
