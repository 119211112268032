<div class="tabs-squid">
  <div class="tabs-container">
    <div
      class="wrapper"
      [ngClass]="{
        'has-overflow': hasOverflow,
        'box-shadow': boxShadow,
        start: isStart && forceOverflow,
        end: isEnd && forceOverflow
      }"
      [ngStyle]="{ 'display': hiddenTabs ? 'none': '' }"
    >
      <span
        class="arrow left"
        (click)="moveTabsPrev()"
        [ngClass]="{
          'line-style': lineStyle,
          'display-none': !forceOverflow,
          hide: !isStart
        }"
      >
        <i class="fa-solid fa-chevron-left"></i>
      </span>
      <ul
        class="tabs-header {{ customClass }}"
        #tabsHeaderContainer
        [ngClass]="{
          'line-style': lineStyle,
          'tabs-center': tabsCenter,
          'tabs-width': tabsWidth,
          'force-overflow': forceOverflow,
          'has-overflow': hasOverflow
        }"
        [ngStyle]="{
          'max-width': maxWidth,
          margin: margin
        }"
      >
        <li
          *ngFor="let tab of tabs; let i = index"
          (click)="selectTab(tab, i, $event)"
          [ngStyle]="{
            height: height,
            margin: tab?.margin || 0
          }"
          [ngClass]="{
            disabled: tab?.disabled,
            active: tab?.active
          }"
          #thisTab
        >
          <div class="display-flex">
            <span [innerHtml]="tab?.title || '' | safeHtml"></span>
            <span *ngIf="tab?.badgeCount" class="badge badge-number">{{ tab?.badgeCount }}</span>
          </div>
        </li>
      </ul>
      <span
        class="arrow right"
        (click)="moveTabsNext()"
        [ngClass]="{
          'line-style': lineStyle,
          'display-none': !forceOverflow,
          hide: !isEnd
        }"
      >
        <i class="fa-solid fa-chevron-right"></i>
      </span>
    </div>
    <div *ngIf="!noContent" class="tabs-wrapper-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
