import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { io } from 'socket.io-client'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  socket: any
  readonly uri: string = environment.websocket.reports

  constructor() {}

  on(token: string) {
    this.socket = io(this.uri, {
      transports: ['websocket'],
      query: {
        auth: token,
      }
    })
  }

  listen(eventName: string) {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data: any) => {
        subscriber.next(data)
      })
    })
  }

  emit(eventName: string, data: any) {
    this.socket.emit(eventName, data)
  }
}
