import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { MultiTranslateHttpLoader } from './helpers/multi-translate.helper'
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http'
import { TranslateModule } from '@ngx-translate/core'
import { DefaultLayoutComponent } from './layouts/default/default.component'
import { ButtonSqComponent } from './components/button-sq/button-sq.component'
import { LoaderComponent } from './components/loader/loader.component'
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe'
import { InputSquidComponent } from './components/input-sq/input-sq.component'
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask, IConfig } from 'ngx-mask'
import { UserFieldMaskDirective } from './directives/userfieldmask/user-field-mask.directive'
import { SelectSquidComponent } from './components/select-sq/select-sq.component'
import { NgxPermissionsModule } from 'ngx-permissions'
import { InfinityScrollComponent } from './components/infinity-scroll/infinity-scroll.component'
import { CalendarSquidComponent } from './components/calendar-sq/calendar-sq.component'
import { FullCalendarModule } from '@fullcalendar/angular'
import { EventCardComponent } from './components/calendar-sq/components/event-card/event-card.component'
import { OverlayComponent } from './components/overlay/overlay.component'
import { InputDateComponent } from './components/input-date/input-date.component'
import { DatePickerComponent } from './components/date-picker/date-picker.component'
import { CheckboxComponent } from './components/input-checkbox/input-checkbox.component'
import { InputCoverComponent } from './components/input-cover/input-cover.component'
import { InputFileComponent } from './components/input-file/input-file.component'
import { EditorSqComponent } from './components/editor-sq/editor-sq.component'
import { TooltipDirective } from './directives/tooltip/tooltip.directive'
import { TabsSquidComponent } from './components/tabs-squid/tabs-squid.component'
import { TabSquidComponent } from './components/tabs-squid/tab-squid/tab-squid.component'
import { CarouselComponent } from './components/carousel/carousel.component'
import { InApprovalTrainingComponent } from './components/in-approval-training/in-approval-training.component'
import { ContextMenuComponent } from './components/calendar-sq/components/context-menu/context-menu.component'
import { MatTableModule } from '@angular/material/table'
import { ReportSearchFormComponent } from './components/report-search-form/report-search-form.component'
import { ModalComponent } from './components/modal/modal.component'
import { PaginationComponent } from './components/pagination/pagination.component'
import { ErrorInterceptor } from './interceptors/error.interceptor.service'
import { NumberToTimePipe } from './pipes/number-to-time.pipe/number-to-time.pipe'
import { ModalSelectConsultantComponent } from './components/modal-select-consultant/modal-select-consultant.component'
import { ModalSelectCategoryComponent } from './components/modal-select-category/modal-select-category.component'
import { SquidCSSModule } from '@squidit/ngx-css'
import { ExportReportsComponent } from './components/export-reports/export-reports.component'

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null

export function HttpLoaderFactory(httpClient: HttpClient) {
  const defaultLocale = '/assets/locale/'
  return new MultiTranslateHttpLoader(httpClient, [
    `${defaultLocale}global`,
    `${defaultLocale}modules/main`,
    `${defaultLocale}modules/login`,
    `${defaultLocale}modules/consultants`,
    `${defaultLocale}modules/reports`,
    `${defaultLocale}modules/admin`,
    `${defaultLocale}modules/manage-consultant-trainings`,
    `${defaultLocale}modules/reports`,
  ])
}

@NgModule({
  declarations: [
    DefaultLayoutComponent,
    ButtonSqComponent,
    LoaderComponent,
    SafeHtmlPipe,
    InputSquidComponent,
    UserFieldMaskDirective,
    SelectSquidComponent,
    InfinityScrollComponent,
    CalendarSquidComponent,
    EventCardComponent,
    OverlayComponent,
    InputDateComponent,
    DatePickerComponent,
    CheckboxComponent,
    InputFileComponent,
    InputCoverComponent,
    EditorSqComponent,
    TooltipDirective,
    TabsSquidComponent,
    TabSquidComponent,
    CarouselComponent,
    InApprovalTrainingComponent,
    ContextMenuComponent,
    ReportSearchFormComponent,
    ModalComponent,
    PaginationComponent,
    NumberToTimePipe,
    ModalSelectConsultantComponent,
    ModalSelectCategoryComponent,
    ExportReportsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxPermissionsModule.forChild(),
    FullCalendarModule,
    MatTableModule,
    SquidCSSModule
  ],
  exports: [
    DefaultLayoutComponent,
    ButtonSqComponent,
    LoaderComponent,
    SafeHtmlPipe,
    InputSquidComponent,
    UserFieldMaskDirective,
    SelectSquidComponent,
    InfinityScrollComponent,
    CalendarSquidComponent,
    EventCardComponent,
    OverlayComponent,
    InputDateComponent,
    DatePickerComponent,
    CheckboxComponent,
    InputFileComponent,
    InputCoverComponent,
    EditorSqComponent,
    TooltipDirective,
    TabsSquidComponent,
    TabSquidComponent,
    CarouselComponent,
    InApprovalTrainingComponent,
    ContextMenuComponent,
    ReportSearchFormComponent,
    ModalComponent,
    PaginationComponent,
    NumberToTimePipe,
    ModalSelectConsultantComponent,
    SquidCSSModule,
    ModalSelectCategoryComponent,
    ExportReportsComponent
  ],
  providers: [provideNgxMask(), { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
