import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core'
import { ConsultantsService } from 'src/app/shared/services/consultants/consultants.service'
import { UserConsultant } from 'src/app/shared/models/user-consultant'
import {
  EMPTY,
  Observable,
  Subject,
  catchError,
  debounceTime,
  filter,
  fromEvent,
  map,
  switchMap,
  takeUntil,
  tap
} from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { ToastHelper } from 'src/app/shared/helpers/toast.helper'
import { ReportsResponse } from 'src/app/shared/models/reports-response'

@Component({
  selector: 'modal-select-consultant',
  templateUrl: './modal-select-consultant.component.html',
  styleUrls: ['./modal-select-consultant.component.scss']
})
export class ModalSelectConsultantComponent implements OnDestroy, OnChanges {

  @Input() open = false
  @Input() consultantType = 'gn'
  @Input() title = ''
  @Input() label = ''
  @Input() placeholder = ''

  @Output() selectedConsultant: EventEmitter<UserConsultant> = new EventEmitter()
  @Output() closeModal: EventEmitter<void> = new EventEmitter()

  consultants: Array<UserConsultant> = []
  loading = true
  page = 1
  pageSize = 20
  cnOrName = ''
  count = 0
  hasMore = true
  loadingScroll = false

  window = window

  private destroy$ = new Subject<void>()
  searchValue = ''

  constructor(
    public consultantsService: ConsultantsService,
    public toastHelper: ToastHelper,
    public translate: TranslateService
    ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.open) {
      if (changes.open.currentValue) {
        this.fetchConsultants().subscribe(() => this.loading = false)
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  fetchConsultants(): Observable<ReportsResponse<UserConsultant>> {
    return this.consultantsService.getConsultantsList(this.page, this.pageSize, this.consultantType, this.cnOrName)
      .pipe(
        tap(response => {
          this.consultants = response.items ?? []
          this.count = response.count ?? 0
        }),
        catchError(error => {
          this.displayError(error)
          return EMPTY
        })
      )
  }

  search(event: any): void {
    if (this.isIgnoredKey(event)) {
      return
    }

    fromEvent(event.target, 'keyup').pipe(
      debounceTime(500),
      filter((value: any) => this.cnOrName !== value.target.value),
      map((value: any) => value.target.value),
      tap((value) => {
        this.cnOrName = value
        this.page = 1
        this.loading = true
        this.hasMore = true
      }),
      switchMap(() => this.fetchConsultants()),
      tap(() => this.loading = false),
      takeUntil(this.destroy$)
    ).subscribe()
  }

  resetSearch(): void {
    this.loading = true
    this.cnOrName = ''
    this.searchValue = ''
    this.page = 1
    this.consultants = []
    this.count = 0
    this.hasMore = true
    this.fetchConsultants().subscribe(() => this.loading = false)
  }

  nextPageConsultants(): void {
    if (this.loadingScroll || !this.hasMore) {
      return
    }

    this.loadingScroll = true
    this.page += 1
    this.consultantsService.getConsultantsList(this.page, this.pageSize, this.consultantType, this.cnOrName)
      .pipe(
        tap(response => {
          if (response.items?.length) {
            this.consultants = this.consultants.concat(response.items ?? [])
            this.count = response.count ?? 0
          } else {
            this.hasMore = false
          }
        }),
        catchError(error => {
          this.displayError(error)
          return EMPTY
        })
      ).subscribe(() => this.loadingScroll = false)
  }

  private displayError(error: any): void {
    const errorKey = `errorCode.${error?.error?.response?.error_id || error?.error?.response?.error_code}`
    this.translate.get(errorKey).subscribe(errorMSg => {
      if (errorKey === errorMSg) {
        this.toastHelper.toast.error(
          this.translate.get('error') + '<br>' + error?.error?.response?.error_code,
          { closeButton: true, position: 'top right' }
        )
      } else {
        this.toastHelper.toast.error(errorMSg, { closeButton: true, position: 'top right' })
      }
    })
  }

  isIgnoredKey(event: KeyboardEvent): boolean {
    const ignoredKeys = [
      'Shift',
      'Control',
      'Alt',
      'Meta',
      'ArrowRight',
      'ArrowLeft',
      'ArrowUp',
      'ArrowDown',
    ]

    return ignoredKeys.includes(event.key)
  }

  closeModalEvent(): void {
    this.cnOrName = ''
    this.searchValue = ''
    this.page = 1
    this.consultants = []
    this.count = 0
    this.hasMore = true
    this.open = false
    this.loading = true
    this.closeModal.emit()
  }
}
