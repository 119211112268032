import { Component, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  @Input() collectionSize = 1
  @Input() perPage = 1
  @Input() currentPage: any = 1
  @Input() showPages = 5
  @Input() readonly = false
  @Input() withQuery = false
  @Input() pageSizeOptions = [20, 30, 40, 50, 60, 70, 80, 90, 100, 500, 1000]
  @Output() changePageEmmiter: EventEmitter<{ page: number, pageSize: number }> = new EventEmitter()

  public pages: Array<number> = []
  parseInt = parseInt
  pageOptions: Array<{value: number, label: string}> = []

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService
  ) {
    this.pageSizeOptions.map(
      size => {
        this.pageOptions.push({ value: size, label: size.toString() })
      }
    )
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.hasOwnProperty('collectionSize') || changes?.hasOwnProperty('perPage')) {
      const pageCount = this.getPageCount()
      this.pages = this.getArrayOfPage(pageCount)
    }
    if (changes?.hasOwnProperty('currentPage') && this.withQuery) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { page: this.currentPage },
        queryParamsHandling: 'merge',
      })
    }
    if (changes?.hasOwnProperty('pageSizeOptions')) {
      this.pageSizeOptions.map(
        size => {
          this.pageOptions.push({ value: size, label: size.toString() })
        }
      )
    }
  }

  showDotMax() {
    const half = Math.floor(this.showPages / 2)
    if (this.currentPage + half < this.pages.length) {
      return true
    }
    return false
  }

  showDotMin() {
    const half = Math.floor(this.showPages / 2)
    if (this.currentPage - half > 1) {
      return true
    }
    return false
  }

  canShow(page: number) {
    const half = Math.floor(this.showPages / 2)
    if (page === this.currentPage) {
      return true
    } else if (page >= this.currentPage - half && page <= this.currentPage + half) {
      return true
    }
    return false
  }

  private getPageCount(): number {
    let totalPage = 0
    if (this.collectionSize > 0 && this.perPage > 0) {
      const pageCount = this.collectionSize / this.perPage
      const roundedPageCount = Math.floor(pageCount)

      totalPage = roundedPageCount < pageCount ? roundedPageCount + 1 : roundedPageCount
    }
    return totalPage
  }

  private getArrayOfPage(pageCount: number): number[] {
    const pageArray = []
    if (pageCount > 0) {
      for (let i = 1; i <= pageCount; i++) {
        pageArray.push(i)
      }
    }
    return pageArray
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  onPageChange(pageNumber: number, active = false) {
    if (!this.readonly && pageNumber >= 1 && pageNumber <= this.pages.length && pageNumber !== this.currentPage && !active) {
      this.changePageEmmiter.emit({page: pageNumber, pageSize: this.perPage})
    }
  }

  onPageSizeChange(size: string) {
    this.changePageEmmiter.emit({ page: 1, pageSize: Number(size) })
  }

  getRageLavel(): string {
    const of = this.translateService ? this.translateService.instant('pagination.of') : 'of'
    if (this.collectionSize === 0 || this.perPage === 0) {
      return `0 ${of} ${this.collectionSize}`
    }
    this.collectionSize = Math.max(this.collectionSize, 0)
    const startIndex =
      (this.currentPage - 1) * this.perPage > this.collectionSize ?
        (Math.ceil(this.collectionSize / this.perPage) - 1) * this.perPage : (this.currentPage - 1) * this.perPage

    const endIndex = Math.min(startIndex + this.perPage, this.collectionSize)
    return `${startIndex + 1} - ${endIndex} ${of} ${this.collectionSize}`
  }
}
