<div
  class="wrapper-input-file-squid"
  [ngClass]="{
    error: (externalError && externalError !== '') || (error && error !== ''),
    block: block
  }"
>
  <label
    *ngIf="label && label.length > 0"
    class="label"
    [ngClass]="{
      loading: loading
    }"
  >
    {{ label }}
  </label>
  <label *ngIf="customContent" [for]="id || timeStamp" class="label-custom">
    <ng-container *ngTemplateOutlet="customContent"></ng-container>
  </label>
  <label
    *ngIf="!customContent"
    class="placeholder button"
    [for]="id || timeStamp"
    [ngStyle]="{
      'font-size': fontSize,
      'line-height': lineHeight,
      color: hover ? setHoverText() : textColor,
      'background-color': hover ? setHoverBg() : color,
      'border-color': hover ? setHover(borderColor || textColor) : borderColor || textColor,
      'text-align': textAlign
    }"
    (mouseover)="hover = true"
    (mouseleave)="hover = false"
    [ngClass]="{
      disabled: disabled,
      loading: loading,
      'no-padding': noPadding
    }"
  >
    <span
      [innerHtml]="placeholder || '' | safeHtml"
      [ngStyle]="{
        'font-size': fontSize
      }"
    ></span>
    <loader-squid *ngIf="loading" color="{{ textColor }}"></loader-squid>
  </label>
  <input
    #inputFile
    type="file"
    [id]="id || timeStamp"
    [name]="name"
    [required]="required"
    [disabled]="disabled || loading"
    [readonly]="disabled || loading"
    [accept]="fileType"
    (click)="inputFile.value = ''"
    (change)="change($event)"
    (keydown)="keyDown($event)"
    [multiple]="multiple"
  />
  <div
    class="box-validation box-invalid show"
    *ngIf="errorSpan"
    [ngClass]="{
      'visibility-hidden-force': ((!externalError || externalError === '') && (!error || error === '')) || disabled || readonly
    }"
  >
    <ng-container *ngIf="externalError || error">
      <i class="fa-solid fa-triangle-exclamation"></i>
    </ng-container>
    {{ externalError ? externalError : '' }}
    {{ error && !externalError ? error : '' }}
  </div>
</div>
