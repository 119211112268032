
import { Injectable } from '@angular/core'
import jwt_decode from 'jwt-decode'
import * as localForage  from 'src/localforage'
import { ConsultantData } from 'src/app/shared/models/consultante-data'


@Injectable({
  providedIn: 'root'
})
export class AuthTokenService {

  localForage: any

  constructor() {
    this.localForage = localForage?.default
  }

  public async getToken(): Promise<string> {
    const token = await this.localForage.getItem('auth-token')
    return token
  }

  public async decodePayloadJWT(): Promise<ConsultantData> {
    try {
      return jwt_decode(await this.getToken())
    } catch (error) {
      throw Error('jwt decode error')
    }
  }
}
