import {
  Component,
  ContentChildren,
  QueryList,
  Output,
  EventEmitter,
  Input,
  AfterContentChecked,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  HostListener,
} from '@angular/core'
import { TabSquidComponent } from './tab-squid/tab-squid.component'

@Component({
  selector: 'tabs-sq',
  templateUrl: './tabs-squid.component.html',
  styleUrls: ['./tabs-squid.component.scss'],
})
export class TabsSquidComponent implements AfterViewInit, AfterContentChecked, OnChanges {
  @ContentChildren(TabSquidComponent) tabs: QueryList<TabSquidComponent> = new QueryList()
  @ViewChild('tabsHeaderContainer') tabsHeaderContainer: ElementRef | undefined

  @Input() height?: string
  @Input() customClass?: string
  @Input() maxWidth = 'initial'
  @Input() margin = '0 auto'
  @Input() boxShadow = true
  @Input() lineStyle = false
  @Input() tabsCenter = false
  @Input() tabsWidth = false
  @Input() noContent = false
  @Input() forceOverflow = false
  @Input() hiddenTabs = false

  @Output() tabChange: EventEmitter<any> = new EventEmitter()

  total = 0
  hasOverflow = false
  isStart = false
  isEnd = false

  constructor() {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.allChecks()
  }

  ngAfterViewInit() {
    const activeTabs = this.tabs.filter((tab) => tab.active)
    if (activeTabs.length === 0) {
      if (this.tabs.first) {
        this.selectTab(this.tabs.first, 0)
      }
    }
    this.total = this.tabs.toArray().length || 1
    this.allChecks()
    this.moveToActiveTab(this.tabsHeaderContainer?.nativeElement.querySelector('.active'))
  }

  ngAfterContentChecked(): void {
    if (this.tabs.toArray().length !== this.total) {
      this.total = this.tabs.toArray().length || 1
      this.allChecks()
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('forceOverflow')) {
      this.allChecks()
    }
  }

  allChecks() {
    this.hasOverflow = this.checkOverflow()
    this.isEnd = this.checkEnds()
    this.isStart = this.checkStarts()
  }

  checkOverflow() {
    if (this.forceOverflow && this.tabsHeaderContainer?.nativeElement?.children?.length) {
      const lastElement = [...this.tabsHeaderContainer.nativeElement.children].pop()
      const lastElementRightOffset = lastElement?.offsetLeft - this.tabsHeaderContainer.nativeElement.offsetLeft + lastElement.offsetWidth
      return lastElementRightOffset > this.tabsHeaderContainer.nativeElement.offsetWidth
    }
    return false
  }

  checkStarts() {
    if (this.forceOverflow && this.tabsHeaderContainer?.nativeElement) {
      return this.tabsHeaderContainer.nativeElement.scrollLeft > 0
    }
    return false
  }

  checkEnds() {
    if (this.forceOverflow && this.tabsHeaderContainer?.nativeElement) {
      const lastElement = [...this.tabsHeaderContainer.nativeElement.children].pop()
      const lastElementRightOffset = lastElement?.offsetLeft - this.tabsHeaderContainer.nativeElement.offsetLeft + lastElement.offsetWidth
      return this.tabsHeaderContainer.nativeElement.offsetWidth + this.tabsHeaderContainer.nativeElement.scrollLeft < lastElementRightOffset
    }
    return false
  }

  moveTabsNext() {
    if (this.forceOverflow && this.tabsHeaderContainer?.nativeElement) {
      this.tabsHeaderContainer.nativeElement.scrollLeft += this.tabsHeaderContainer.nativeElement.offsetWidth
      setTimeout(() => {
        this.allChecks()
      }, 500)
    }
  }

  moveTabsPrev() {
    if (this.forceOverflow && this.tabsHeaderContainer?.nativeElement) {
      this.tabsHeaderContainer.nativeElement.scrollLeft -= this.tabsHeaderContainer.nativeElement.offsetWidth
      setTimeout(() => {
        this.allChecks()
      }, 500)
    }
  }

  checkIfActiveTabIsVisible(tab?: HTMLElement) {
    if (tab) {
      const width = tab.offsetWidth
      const position = tab.offsetLeft
      return (
        width + position <= this.tabsHeaderContainer?.nativeElement.offsetWidth &&
        position >= this.tabsHeaderContainer?.nativeElement.scrollLeft
      )
    }
    return false
  }

  moveToActiveTab(tabHtml?: HTMLElement) {
    if (!this.checkIfActiveTabIsVisible(tabHtml) && tabHtml && this.tabsHeaderContainer && this.forceOverflow) {
      const tabRightOffset = tabHtml.offsetLeft - this.tabsHeaderContainer.nativeElement.offsetLeft + tabHtml.offsetWidth
      this.tabsHeaderContainer.nativeElement.scrollLeft = tabRightOffset
      setTimeout(() => {
        this.allChecks()
      }, 500)
    }
  }

  selectTab(tab: TabSquidComponent, index: number, event?: MouseEvent) {
    if (!tab.disabled) {
      this.tabs.toArray().forEach((tabItem) => (tabItem.active = false))
      if (tab) {
        this.tabChange.emit({
          tab,
          index,
        })
        tab.active = true
        this.moveToActiveTab(event?.target as HTMLElement)
        if (tab.whenOpen) {
          tab.whenOpen.emit()
        }
      }
    }
  }
}
