<loader *ngIf="loading" class="display-flex text-center align-self-center" customClass="big"></loader>
<div id='default-layout' *ngIf="!loading">

  <div class="mobile-header">
    <span (click)="collapseFixe = !collapseFixe ; collapse = false"><i class="fa-light fa-bars"></i></span>
    <div class="logo">
      <img src="{{dashLogo}}" alt="{{ 'dashboardTitle' | translate}}" title="{{ 'dashboardTitle' | translate}}">
      <p [innerHTML]="'menuSubtitle' | translate"></p>
    </div>
  </div>

  <div
    class="backdrop"
    *ngIf="collapseFixe"
    (click)="collapseFixe = false;"
  ></div>

  <div class="side-nav box-shadow" [ngClass]="{'collapse': collapse, 'collapse-fixe': collapseFixe}">
    <header>
      <div class="logo">
        <img src="{{dashLogo}}" alt="{{ 'dashboardTitle' | translate}}" title="{{ 'dashboardTitle' | translate}}">
        <p [innerHTML]="'menuSubtitle' | translate"></p>
      </div>
      <span class="close" (click)="collapseFixe = false"><i class="fa-sharp fa-light fa-xmark"></i></span>
      <div class="button-side-nav">
        <sq-button
          [borderRadius]="'40px'"
          [noPadding]="true"
          [width]="'25px'"
          color="primary"
          (emitClick)="collapseFixe = !collapseFixe ; collapse = false"
        >
          <i
            class="fa-light fa-chevron-right"
            [ngStyle]="{'transform': collapseFixe ? 'rotatey(180deg)' : 'rotatey(0deg)'}"
          ></i>
        </sq-button>
      </div>
    </header>
    <aside
      class="side-nav-body"
      [ngClass]="{'collapse': collapse || collapseFixe}"
      (mouseenter)="window.innerWidth > 991 ? !collapseFixe && collapse = true : collapseFixe = true ; collapse = true"
      (mouseleave)="collapse = false"
    >
      <nav>
        <ul class='itens'>
          <ng-container *ngFor='let item of menu'>
            <li *ngxPermissionsOnly="item.permissions">
              <ng-container *ngIf='item?.submenu?.length'>
                <h3
                    class='link'
                    [routerLink]='null'
                    routerLinkActive='active'
                    [routerLinkActiveOptions]="{ exact: true }"
                    [ngClass]="{ 'active':  item.module === collapsedModule}"
                    (click)="colapseMenu(item.module)"
                  >
                    <span
                      class='icon'
                      [innerHTML]='item.icon | safeHtml'
                    ></span>
                    <span class='text with-submenu'>
                      <span>{{item.title | translate}}</span>
                      <i
                        class="fa-sharp fa-regular"
                        [ngClass]="{
                          'fa-chevron-down': collapsedModule !== item.module,
                          'fa-chevron-up': collapsedModule === item.module
                        }"></i>
                    </span>
                  </h3>
                <ul
                  class='submenu'
                  [ngClass]="{'collapse': collapsedModule === item.module}"
                  *ngIf="collapse || collapseFixe">
                  <ng-container *ngFor='let subitem of item?.submenu'>
                    <li *ngxPermissionsOnly="subitem.permissions">
                      <a
                        [routerLink]='subitem?.url'
                        routerLinkActive='active'
                        [routerLinkActiveOptions]="{ exact: true }"
                        #[{{item.module}}]="routerLinkActive"
                        (click)="window.innerWidth > 769 ? null : collapseFixe = false ; collapse = false "
                      >
                        {{ subitem.title | translate }}
                      </a>
                    </li>
                  </ng-container>
                </ul>
              </ng-container>
              <ng-container *ngIf='!item?.submenu?.length'>
                <h3
                  class='link'
                  [routerLink]='item?.url'
                  routerLinkActive='active'
                  [routerLinkActiveOptions]="{ exact: true }"
                  (click)="window.innerWidth > 991 ? null : collapseFixe = false ; collapse = false"
                >
                  <span
                    class='icon'
                    [innerHTML]='item.icon | safeHtml'
                  ></span>
                  <span class='text'>
                    {{item.title | translate}}
                  </span>
                </h3>
              </ng-container>
            </li>
          </ng-container>
        </ul>
      </nav>
      <footer
        [ngClass]="{'collapse': collapse || collapseFixe}"
        (click)="window.innerWidth > 991 ? null : collapseFixe = true ; collapse = true"
      >
        <div class="user-info">
          <img src="{{user?.user_image}}" alt="">
          <div>
            <span>{{user?.name}}</span>
            <p>{{user?.email}}</p>
          </div>
        </div>
        <div class="theme-buttons">
          <div style="width: 100%;">
            <input-checkbox
              [id]="'select-category-'"
              [name]="'select-category-'"
              [hideInput]="true"
              [direction]="'left'"
              [toggleSwitch]="true"
              [checked]="!isDarkMode"
              [colorText]="'var(--text_color)'"
              (sharedValue)="changeColorScheme()"
            >
              <ng-template let-checked="checked" let-value="value" #labelRight>
                {{ 'light' | translate}}
              </ng-template>
            </input-checkbox>
            <input-checkbox
              [id]="'select-category-'"
              [name]="'select-category-'"
              [hideInput]="true"
              [direction]="'right'"
              [toggleSwitch]="true"
              [checked]="isDarkMode"
              (sharedValue)="changeColorScheme()"
            >
              <ng-template let-checked="checked" let-value="value" #labelRight>
                {{ 'dark' | translate}}
              </ng-template>
            </input-checkbox>
          </div>

          <button-sq
            [textColor]="'var(--primary_color)'"
            [borderColor]="'transparent'"
            [color]="'transparent'"
            [disabled]="loadingLogout"
            (emitClick)="logout()"
          >
          {{ 'exit' | translate}} <i class="fa-regular fa-arrow-right-from-bracket"></i>
          </button-sq>
        </div>
      </footer>
    </aside>
  </div>
  <main [ngClass]="{'collapse-fixe': collapseFixe}">
    <router-outlet></router-outlet>
  </main>
</div>
