<div id="report-search-form">
  <h2>{{ title }}</h2>
  <div class="search-form">
    <div class="row">
      <span
        style="padding-bottom: 1rem"
        *ngIf="window.innerWidth < 768"
        (click)="showMoreOptions = !showMoreOptions">
          {{ 'reportSearchForm.moreOptions' | translate}}
          <i
            class="fa-sharp fa-regular"
            [ngClass]="{
              'fa-chevron-down': showMoreOptions,
              'fa-chevron-up': !showMoreOptions
            }"></i>
        </span>
      <div
        class="col-24"
        [ngClass]="rightButton || showSelectSubcategory ? 'col-sm-12 col-md-14 col-lg-16' : 'col-sm-24 col-md-8 col-lg-24'"
        [ngStyle]="{
          'height': !showMoreOptions && window.innerWidth < 768 ? '0' : '100px',
          'overflow': 'hidden'
        }"
      >
        <input-sq
          [label]="'reportSearchForm.searchConsultant' | translate"
          [value]="value.name.value"
          [readonly]="value.name.readonly || false"
          [disabled]="value.name.disabled || false"
          [placeholder]="'reportSearchForm.searchConsultantPlaceholder' | translate"
          (sharedValue)="value.name.value = $event"
          (sharedKeyPress)="$event.key === 'Enter' && value.date.value && !disbled && !dateError ? getFormValue() : null"
        ></input-sq>
      </div>
      <div
        class="col-24 col-sm-12 col-md-10 col-lg-8"
        *ngIf="rightButton"
        [ngStyle]="{'height': !showMoreOptions && window.innerWidth < 768 ? '0' : '100px', 'overflow': 'hidden'}"
      >
        <span class="input-group-text ml-3">
          <ng-container *ngTemplateOutlet="rightButton"></ng-container>
        </span>
      </div>
      <div class="col-24 col-sm-12 col-md-10 col-lg-8 display-flex" *ngIf="showSelectSubcategory">
        <sq-input [label]="'schedule.form.categoryAndDescription.category' | translate" [value]="selectedCategory.name" [readonly]="true" class="mr-3">
          <ng-template #rightLabel>
            <div style="cursor: pointer;">
              <span (click)="selectedCategory = resetCategory() ; selectedSubcategory = resetCategory() ; openSelectCategoryModal = true">
                <i class="far fa-pen"></i>
              </span>
            </div>
          </ng-template>
        </sq-input>
        <sq-input [label]="'schedule.form.categoryAndDescription.subCategory' | translate" [value]="selectedSubcategory.name" [readonly]="true">
          <ng-template #rightLabel>
            <div style="cursor: pointer;">
              <span (click)="selectedSubcategory = resetCategory() ; openSelectCategoryModal = true">
                <i class="far fa-pen"></i>
              </span>
            </div>
          </ng-template>
        </sq-input>
      </div>
      <div
        class="col-16 col-sm-17 col-lg-14"
        [ngClass]="rightButton ? 'col-md-19' : 'col-md-11'"
      >
        <div class="display-flex" *ngIf="dateType === 'range' ; else monthInput">
          <input-sq
            class="mr-3"
            [label]="'reportSearchForm.start' | translate"
            [value]="value.startDate.value"
            [type]="'date'"
            [readonly]="value.startDate.readonly || false"
            [disabled]="value.startDate.disabled || false"
            [required]="true"
            [externalError]="dateError | translate"
            (sharedValue)="setValue('startDate', $event)"
          ></input-sq>
          <input-sq
            [label]="'reportSearchForm.end' | translate"
            [value]="value.endDate.value"
            [type]="'date'"
            [readonly]="value.endDate.readonly || false"
            [disabled]="value.endDate.disabled || false"
            [required]="true"
            [externalError]="dateError | translate"
            (sharedValue)="setValue('endDate', $event)"
          ></input-sq>
        </div>
        <ng-template #monthInput>
          <input-sq
            [label]="'reportSearchForm.filterBy' | translate"
            [value]="value.date.value"
            [type]="'month'"
            [readonly]="value.date.readonly || false"
            [disabled]="value.date.disabled || false"
            [required]="true"
            [externalError]="dateError | translate"
            (sharedValue)="parseMonth($event)"
          ></input-sq>
        </ng-template>
      </div>
      <div class="col-8 col-sm-7 col-md-5 col-lg-10">
        <div class="display-flex" style="justify-content: flex-end;column-gap: 0.5rem;">
          <button-sq
            [size]="'lg'"
            [textColor]="'var(--white-html)'"
            [invertedHover]="true"
            [loading]="loading"
            [disabled]="!value.date.value || disbled || !!dateError"
            [tooltip]="!value.date.value ? ('reportSearchForm.dateEmpty' | translate) : ''"
            [block]="true"
            (emitClick)="getFormValue()"
          >
            <i class="fa-regular fa-magnifying-glass"></i> {{ window.innerWidth > 992 ? ('listConsultants.search' | translate) : '' }}
          </button-sq>
          <button-sq
            [size]="'lg'"
            [textColor]="'var(--primary_color)'"
            [color]="'transparent'"
            [invertedHover]="true"
            [block]="true"
            [loading]="loading"
            (emitClick)="resetForm()"
          >
            <i class="fa-sharp fa-regular fa-trash"></i> {{ window.innerWidth > 992 ? ('listConsultants.clean' | translate) : '' }}
          </button-sq>
        </div>
      </div>
    </div>
  </div>
</div>
<modal-select-category
  [open]="openSelectCategoryModal"
  [selectedCategory]="selectedCategory"
  (sharedCategory)="selectedCategory = $event"
  (sharedSubcategory)="selectedSubcategory = $event ; openSelectCategoryModal = false"
  (modalClose)="openSelectCategoryModal = false"
></modal-select-category>
