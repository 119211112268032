<div
  class='carousel-parent'
  [ngClass]='{
    "format": format
  }'
  #parent
>
  <div
    *ngIf='showArrows'
    class='controls prev'
    [ngClass]='{
      "hide-end": hideOnEnd,
      "start": (!currentSlide || currentSlide === 1 || currentSlide === toScroll) && !infinite
    }'
    [ngStyle]='{
      "height": itemHeight
    }'
    (click)='prev()'
  >
    <ng-container
      [ngTemplateOutlet]='prevArrow || null'
      [ngTemplateOutletContext]='{
        height: itemHeight,
        disabled: (!currentSlide || currentSlide === 1 || currentSlide === toScroll) && !infinite,
        loading: loaderButtons
      }'
    ></ng-container>
    <button-sq
      *ngIf='!prevArrow'
      [color]="(!currentSlide || currentSlide === 1 || currentSlide === toScroll) && !infinite ? 'var(--gray)' : 'var(--primary_color)'"
      [borderColor]="(!currentSlide || currentSlide === 1 || currentSlide === toScroll) && !infinite ? 'var(--gray)' : 'var(--primary_color)'"
      [textColor]="'var(--white-html)'"
      [height]="itemHeight"
      [loading]="loaderButtons"
    >
      <i *ngIf='!loaderButtons' class="fas fa-chevron-left"></i>
    </button-sq>
  </div>
  <section
    class='carousel-wrapper'
    #wrapper
  >
    <ul
      class='carousel-inner'
      [ngClass]='{
        "adaptative": !itemHeight && variableHeight
      }'
      [ngStyle]='{
        "height": itemHeight || ((variableHeight) ? (itensHtml[currentSlide - 1].nativeElement?.firstChild?.offsetHeight + "px") : null),
        "width": (itens?.length || 0) * 1000 + "px"
      }'
      #carousel
    >
      <li
        *ngFor='let item of items; let i = index; trackBy: trackSlide'
        class='carousel-slide'
        [ngStyle]='{
          "height": itemHeight,
          "width": itemWidth,
          "max-width": wrapper?.getBoundingClientRect()?.width + "px"
        }'
        [ngClass]='{
          "item-active": currentSlide === (i + 1)
        }'
        hammer
        (swipeRight)="swipe($event, 'right')"
        (swipeLeft)="swipe($event, 'left')"
        #itens
      >
        <ng-container
          [ngTemplateOutlet]='itemLayout'
          [ngTemplateOutletContext]='{
            index: i,
            item: item
          }'
        ></ng-container>
      </li>
    </ul>
  </section>
  <div *ngIf='showDots' class='dots'>
    <ul
      class='dots-list'
      [ngClass]='{
        "custom-dots": !!dots
      }'
    >
      <!-- TODO: Implementar dots de forma que se existirem muitos em tela não vazem o slider -->
      <ng-container *ngFor='let item of items; let i = index'>
        <li
          class='dot'
          [ngClass]='{
            "readonly": dotsReadOnly
          }'
          (click)='dotClick(i)'
        >
          <div
            *ngIf='!dots'
            class='default-dot'
          >
            <span
              [ngClass]='{
                "item-active": currentSlide === (i + 1)
              }'
            ></span>
          </div>
          <ng-container
            [ngTemplateOutlet]='dots || null'
            [ngTemplateOutletContext]='{
              activeIndex: currentSlide,
              index: i
            }'
          ></ng-container>
        </li>
      </ng-container>
    </ul>
  </div>
  <div
    *ngIf='showArrows'
    class='controls next'
    [ngClass]='{
      "hide-end": hideOnEnd,
      "end": (currentSlide || 0) >= (itens?.length || 0) && !infinite
    }'
    [ngStyle]='{
      "height": itemHeight
    }'
    (click)='next()'
  >
    <ng-container
      [ngTemplateOutlet]='nextArrow || null'
      [ngTemplateOutletContext]='{
        height: itemHeight,
        disabled: (currentSlide || 0) >= (itens?.length || 0) && !infinite,
        loading: loaderButtons
      }'
    ></ng-container>
    <button-sq
      *ngIf='!nextArrow'
      [color]="(currentSlide || 0) >= (itens?.length || 0) && !infinite ? 'var(--gray)' : 'var(--primary_color)'"
      [borderColor]="(currentSlide || 0) >= (itens?.length || 0) && !infinite ? 'var(--gray)' : 'var(--primary_color)'"
      [textColor]="'var(--white-html)'"
      [height]="itemHeight"
      [loading]="loaderButtons"
    >
      <i *ngIf='!loaderButtons' class="fas fa-chevron-right"></i>
    </button-sq>
  </div>
</div>
