import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { lastValueFrom, Subscription } from 'rxjs'
import * as localForage  from 'src/localforage'
import { ToastHelper } from './shared/helpers/toast.helper'
import { ConsultantData } from './shared/models/consultante-data'
import { AuthService } from './shared/services/auth/auth.service'
import { AuthenticationService } from './shared/services/auth/authentication.service'
import { UserService } from './shared/services/user/user.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  localForage: any
  loading = true

  loadingSupportLink = false
  authenticateObservable!: Subscription
  user: ConsultantData | null = null

  constructor(
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public authService: AuthService,
    public toastHelper: ToastHelper,
    public translate: TranslateService,
    public userService: UserService,
    public authenticationService: AuthenticationService
  ) {
    this.localForage = localForage?.default
  }

  async ngOnInit(): Promise<void> {
    const createInCss: (key: string, value: string) => string = (window as any)['createInCss']
    createInCss('primary', '#EB6619')
    const lightColors = `
      --primary_color: var(--primary-40) !important;
      --primary_color_hover: var(--primary-50) !important;
    `
    const darkColors = `
      --primary_color: var(--primary-50) !important;
      --primary_color_hover: var(--primary-60) !important;
    `
    const variables = `
      html {
        --secondary_color: var(--white-html) !important;
        ${lightColors}
      }
      @media (prefers-color-scheme: dark) {
        html {
          ${darkColors}
        }
      }
      html.light {
        ${lightColors}
      }
      html.dark {
        ${darkColors}
      }
    `
    const colorScheme = await this.localForage.getItem('color-scheme')
    const html = document.getElementsByTagName('html')[0]
    if (colorScheme && !html.classList.contains(colorScheme)) {
      html.classList.remove(colorScheme === 'dark' ? 'light' : 'dark')
      html.classList.add(colorScheme)
    } else if (window?.matchMedia && window?.matchMedia('(prefers-color-scheme: dark)')?.matches) {
      html.classList.add('dark')
    } else {
      html.classList.add('light')
    }

    const head = document.getElementsByTagName('head')[0]
    const style = document.createElement('style')
    style.appendChild(document.createTextNode(variables))
    head.appendChild(style)

    await this.login()
    await this.updateToken()
    this.authenticateObservable = this.userService.currentUserSubject?.subscribe(
      async (user) => {
        if (user) {
          this.user = user
        }

        this.loading = false
      }
    )
  }

  ngOnDestroy(): void {
    this.authenticateObservable?.unsubscribe()
  }

  private async login(): Promise<void> {
    this.activatedRoute.queryParams.subscribe(async (params) => {
      if (params?.token) {
        await this.localForage.setItem('auth-token', params.token)
        await this.router.navigate([], { relativeTo: this.activatedRoute, queryParamsHandling: '' })
        if (location.pathname === '/') {
          this.router.navigate(['/consultants'])
        }
      }
    })
  }

  async updateToken(): Promise<void> {

    const token = await this.localForage.getItem('auth-token')

    if (token) {
      const resp$ = await this.authenticationService.updateToken()
      const resp = await lastValueFrom(resp$)

      if (resp.result === 'TOKEN_PERMISSIONS_HAS_BEEN_UPDATED') {
        await this.localForage.setItem('auth-token', resp.data)
      }
    }
  }

  async openSupportLink(): Promise<void> {
    const phoneNumber = '5511948972729'
    let text = await lastValueFrom(this.translate.get('suport.text1'))
    if (this.user && this.user.consultant_id) {
      text += await lastValueFrom(this.translate.get('suport.text2', { userId : this.user.consultant_id}))
    }
    const url = `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=${text}&type=phone_number&app_absent=0`
    window.open(encodeURI(url), "_blank")
  }
}
