import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { WebSocketService } from '../../services/socket/socket.service'
import { AuthTokenService } from '../../services/auth/auth-token.service'
import { ToastHelper } from '@squidit/ngx-css'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'export-reports',
  templateUrl: './export-reports.component.html',
  styleUrls: ['./export-reports.component.scss']
})
export class ExportReportsComponent implements OnInit, OnChanges {

  @Input() open = false
  @Input() reportType = ''
  @Input() reportBody = {}

  @Output() emitClose: EventEmitter<void> = new EventEmitter()

  token = ''
  loading = true
  progress = 0
  completed = false


  constructor(
    private websocket: WebSocketService,
    private authTokenService: AuthTokenService,
    public toastHelper: ToastHelper,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.authTokenService.getToken().then(token => {
      this.token = token
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.open && changes.open.currentValue) {
      this.initExport()
    }
  }

  initExport() {
    if(this.token && this.reportType && this.reportBody) {
      this.websocket.on(this.token)
      this.websocket.emit(this.reportType, this.reportBody)
      this.webSocketEvents()
    }
  }

  webSocketEvents() {
    this.websocket.listen('update').subscribe((data: any) => {
      if (data.type === 'progress-update') {
        this.loading = false
        this.progress = data.progress * 100
      }
      if (data.type === 'export-finished') {
        this.loading = false
        this.completed = true
        window.location.assign(data.signedUrl)
      }
    })
    this.websocket.listen('unauthorized').subscribe(() => {
      this.displayError('errorCode.150000')
      this.close()
    })
  }

  close() {
    this.websocket.socket.disconnect()
    this.progress = 0
    this.completed = false
    this.loading = true
    this.open = false
    this.emitClose.emit()
  }

  private displayError(error: string): void {
    this.translate.get(error).subscribe(errorMSg => {
      this.toastHelper.toast.error(errorMSg, { closeButton: true, position: 'top right' })
    })
  }
}
