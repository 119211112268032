import { Injectable } from '@angular/core'
import { MainService } from '../main/main.service'
import { HttpClient, HttpParams } from '@angular/common/http'
import { AuthTokenService } from '../auth/auth-token.service'
import { environment } from 'src/environments/environment'
import { ApiResponse } from 'src/app/shared/models/api-response.model'
import { Observable, from, map, switchMap } from 'rxjs'
import { CategoriesInterface } from 'src/app/shared/models/categories.interface'

@Injectable({
  providedIn: 'root'
})
export class CategoriesService extends MainService {

  public url: string

  constructor(
    http: HttpClient,
    authService: AuthTokenService,
  ) {
    super(http, authService)
    this.url = environment.apiRouting.community
  }

  getCategories(
    name = '',
    page = 1,
    pageSize = 20,
    includeHiddenCategories = false,
    includeTrainingCount = false,
  ): Observable<{count : number, content : Array<CategoriesInterface>}> {

    const params = new HttpParams()
      .set('name', name)
      .set('page', page.toString())
      .set('page_size', pageSize.toString())
      .set('include_hidden_categories', includeHiddenCategories.toString())
      .set('include_training_count', includeTrainingCount.toString())

    return from(this.getHeaders()).pipe(
      switchMap(headers =>
        this.http.get<ApiResponse<{count : number, content : Array<CategoriesInterface>}>>(
          `${this.url}/natura/trainings-categories/`, {...headers, params}
        )
      ),
      map((resp: ApiResponse<{count : number, content : Array<CategoriesInterface>}>) => resp.response?.data)
    )
  }

  postCategory(category: CategoriesInterface): Observable<CategoriesInterface> {
    return from(this.getHeaders()).pipe(
      switchMap(headers =>
        this.http.post<ApiResponse<CategoriesInterface>>(
          `${this.url}/natura/trainings-categories/`, category, headers
        )
      ),
      map((resp: ApiResponse<CategoriesInterface>) => resp.response?.data)
    )
  }

  putCategory(category: CategoriesInterface): Observable<CategoriesInterface> {
    return from(this.getHeaders()).pipe(
      switchMap(headers =>
        this.http.put<ApiResponse<CategoriesInterface>>(
          `${this.url}/natura/trainings-categories/${category.id}`, category, headers
        )
      ),
      map((resp: ApiResponse<CategoriesInterface>) => resp.response?.data)
    )
  }

  deleteCategory(categoryId: string): Observable<CategoriesInterface> {
    if (!categoryId) {
      throw new Error('categoryId is required')
    }

    return from(this.getHeaders()).pipe(
      switchMap(headers =>
        this.http.delete<ApiResponse<CategoriesInterface>>(
          `${this.url}/natura/trainings-categories/${categoryId}`, headers
        )
      ),
      map((resp: ApiResponse<CategoriesInterface>) => resp.response?.data)
    )
  }

  getSubcategories(
    categoryId: string,
    name = '',
    includeHiddenCategories = false,
    includeTrainingCount = false,
    page?: number,
    pageSize?: number,
  ): Observable<{count : number, content : Array<CategoriesInterface>}> {

    if (!categoryId) {
      throw new Error('categoryId is required')
    }

    const params = new HttpParams()
      .set('name', name)
      .set('include_hidden_categories', includeHiddenCategories.toString())
      .set('include_training_count', includeTrainingCount.toString())

      if (page) {
        params.set('page', page.toString())
      }

      if (pageSize) {
        params.set('page_size', pageSize.toString())
      }

    return from(this.getHeaders()).pipe(
      switchMap(headers =>
        this.http.get<ApiResponse<{count : number, content : Array<CategoriesInterface>}>>(
          `${this.url}/natura/trainings-categories/${categoryId}/subcategories`, {...headers, params}
        )
      ),
      map((resp: ApiResponse<{count : number, content : Array<CategoriesInterface>}>) => resp.response?.data)
    )
  }

  postSubcategory(categoryId: string, category: CategoriesInterface): Observable<CategoriesInterface> {
    if (!categoryId) {
      throw new Error('categoryId is required')
    }

    return from(this.getHeaders()).pipe(
      switchMap(headers =>
        this.http.post<ApiResponse<CategoriesInterface>>(
          `${this.url}/natura/trainings-categories/${categoryId}/subcategories`, category, headers
        )
      ),
      map((resp: ApiResponse<CategoriesInterface>) => resp.response?.data)
    )
  }

  putSubcategory(subcategory: CategoriesInterface): Observable<CategoriesInterface> {

    return from(this.getHeaders()).pipe(
      switchMap(headers =>
        this.http.put<ApiResponse<CategoriesInterface>>(
          `${this.url}/natura/trainings-categories/${subcategory.natura_trainings_category_id}/subcategories/${subcategory.id}`, subcategory, headers
        )
      ),
      map((resp: ApiResponse<CategoriesInterface>) => resp.response?.data)
    )
  }

  deleteSubcategory(subcategory: CategoriesInterface): Observable<CategoriesInterface> {
    return from(this.getHeaders()).pipe(
      switchMap(headers =>
        this.http.delete<ApiResponse<CategoriesInterface>>(
          `${this.url}/natura/trainings-categories/${subcategory.natura_trainings_category_id}/subcategories/${subcategory.id}`, headers
        )
      ),
      map((resp: ApiResponse<CategoriesInterface>) => resp.response?.data)
    )
  }
}
