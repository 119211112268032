<div
  class="wrapper-all-inside-input {{ customClass }}"
  [ngClass]="{
    'mb-3': marginBottom
  }"
>
  <label
    *ngIf="label && label.length > 0"
    class="label"
    [ngClass]="{
      error: (externalError && externalError !== '') || (error && error !== ''),
      disabled: disabled,
      readonly: readonly,
      small: small
    }"
    [for]="id"
    [innerHtml]="label | safeHtml"
  ></label>
  <label *ngIf="!required && showLabelOptionalField" class="optional-field"> ({{ 'optional' | translate | lowercase }}
    ) </label>
  <div
    class="no-padding wrapper-input wrapper-input-squid"
    [ngClass]="{
      error: (externalError && externalError !== '') || (error && error !== ''),
      disabled: disabled,
      readonly: readonly,
      'wrapper-textarea': type === 'textarea'
    }"
  >
    <span class="input-group-text" *ngIf="leftLabel">
      <ng-container *ngTemplateOutlet="leftLabel"></ng-container>
    </span>
    <input
      *ngIf="type !== 'textarea' && type !== 'userField' && type !== 'currency'"
      class="col input"
      [ngStyle]="{
        'background-color': backgroundColor
      }"
      [ngClass]="{
        'has-icon': error || externalError,
        error: (externalError && externalError !== '') || (error && error !== ''),
        disabled: disabled,
        readonly: readonly,
        'has-icon-external': externalIcon,
        'has-icon-extra-padding': type === 'number',
        small: small,
        borderless: borderLess
      }"
      [id]="id || ''"
      [type]="type || 'text'"
      [name]="name"
      [placeholder]="placeholder || ''"
      [required]="required"
      [disabled]="disabled"
      [readonly]="readonly"
      [min]="min || ''"
      [max]="max || ''"
      (blur)="validate(true)"
      [ngModel]="thisValue"
      [maxlength]="maxLength"
      [mask]="mask || ''"
      [suffix]="maskSuffix || ''"
      [thousandSeparator]="thousandSeparator || ''"
      (ngModelChange)="change($event)"
      (keydown)="keyDown($event)"
      (focus)="sharedFocus.emit(true)"
      [autocomplete]="autocomplete"
      ngDefaultControl
    />
    <input
      *ngIf="type === 'currency'"
      class="col input"
      [ngStyle]="{
        'background-color': backgroundColor
      }"
      [ngClass]="{
        'has-icon': error || externalError,
        error: (externalError && externalError !== '') || (error && error !== ''),
        disabled: disabled,
        readonly: readonly,
        'has-icon-external': externalIcon,
        small: small,
        borderless: borderLess
      }"
      [id]="id || ''"
      [type]="'text'"
      [name]="name"
      [placeholder]="placeholder || ''"
      [required]="required"
      [disabled]="disabled"
      [readonly]="readonly"
      (ngModelChange)="change($event)"
      (keydown)="keyDown($event)"
      (focus)="sharedFocus.emit(true)"
      [maxlength]="maxLength"
      [ngModel]="thisValue"
      currency-mask
      ngDefaultControl
      #input
    />
    <input
      *ngIf="type === 'userField'"
      class="col input"
      [ngStyle]="{
        'background-color': backgroundColor
      }"
      [ngClass]="{
        'has-icon': error || externalError,
        error: (externalError && externalError !== '') || (error && error !== ''),
        disabled: disabled,
        readonly: readonly,
        'has-icon-external': externalIcon,
        small: small,
        borderless: borderLess
      }"
      [id]="id || ''"
      [type]="'text'"
      [name]="name"
      [placeholder]="placeholder || ''"
      [required]="required"
      [disabled]="disabled"
      [readonly]="readonly"
      [min]="min || ''"
      [max]="max || ''"
      (blur)="validate(true)"
      [ngModel]="thisValue"
      [maxlength]="maxLength"
      (ngModelChange)="change($event)"
      (keydown)="keyDown($event)"
      (focus)="sharedFocus.emit(true)"
      [autocomplete]="autocomplete"
      [userFieldMask]="displayMask"
      [sanitizationMask]="sanitizationMask"
      [validationMask]="validationMask"
      (maskError)="maskError($event)"
      ngDefaultControl
      #input
    />
    <textarea
      *ngIf="type === 'textarea'"
      class="col textarea scrollbar"
      [ngStyle]="{
        'background-color': backgroundColor
      }"
      [ngClass]="{
        error: (externalError && externalError !== '') || (error && error !== ''),
        disabled: disabled,
        readonly: readonly,
        'has-icon-external': externalIcon,
        small: small,
        borderless: borderLess
      }"
      [id]="id || ''"
      [name]="name"
      [placeholder]="placeholder || ''"
      [required]="required"
      [disabled]="disabled"
      [readonly]="readonly"
      (blur)="validate(true)"
      (focus)="sharedFocus.emit(true)"
      [maxlength]="maxLength"
      [ngModel]="thisValue"
      (ngModelChange)="change($event)"
      ngDefaultControl
      #input
    >{{ thisValue }}</textarea
    >
    <span class="input-group-text" *ngIf="rightLabel">
      <ng-container *ngTemplateOutlet="rightLabel"></ng-container>
    </span>
    <loader *ngIf="hideIconOnLoading" class="display-block text-center my-2 mr-4"></loader>
  </div>
  <span
    class="icon icon-external"
    *ngIf="externalIcon"
    [ngClass]="{
      'textarea-icon': type === 'textarea',
      'no-label': !label || !label.length,
      'disabled-icon-click': disableEmptyInputIconClick && !enableEventEmit,
      'enabled-icon-click': disableEmptyInputIconClick && enableEventEmit,
      'display-none': hideIconOnLoading,
      small: small
    }"
    (click)="clickIcon($event)"
  >
    <ng-container *ngTemplateOutlet="externalIcon"></ng-container>
  </span>
  <div
    class="box-validation box-invalid show"
    *ngIf="errorSpan"
    [ngClass]="{
      'has-max-length': maxLength
    }"
  >
    <ng-container *ngIf="externalError || error">
      <i class="fa-solid fa-triangle-exclamation"></i>
    </ng-container>
    {{ externalError ? externalError : '' }}
    {{ error && !externalError ? error : '' }}
    <span
      class="max-length-name"
      [ngClass]="{
        'visibility-hidden-force': disabled || readonly
      }"
      *ngIf="maxLength"
    >
      {{ maxLength - thisValue?.length }}
    </span>
  </div>
</div>
