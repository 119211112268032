import { Directive, ElementRef, EventEmitter, forwardRef, HostListener, Input, Output, Renderer2 } from '@angular/core'
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms'

@Directive({
  selector: '[userFieldMask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserFieldMaskDirective),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => UserFieldMaskDirective),
      multi: true,
    },
  ],
})
export class UserFieldMaskDirective implements ControlValueAccessor, Validator {
  @Input('userFieldMask') displayMask?: Array<string>
  @Input() sanitizationMask?: string
  @Input() validationMask?: string

  @Output() maskError: EventEmitter<boolean> = new EventEmitter<boolean>()

  private inputValue: string
  private backspace = false
  private formElement: HTMLInputElement

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.formElement = this.elementRef.nativeElement
    this.inputValue = ''
  }

  @HostListener('input', ['$event'])
  public onInput(): void {
    this.applyMask(this.formElement.value)
  }

  @HostListener('blur')
  onBlur() {
    this.onTouch()
  }

  @HostListener('keydown', ['$event'])
  keydownBackspace(event: KeyboardEvent) {
    this.backspace = event.key === 'Backspace'
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onChange = (_: any) => {}

  public onTouch = () => {}

  public registerOnChange(fn: any): void {
    this.onChange = fn
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn
  }

  public writeValue(value: any): void {
    this.applyMask(value)
  }

  public setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled)
    this.backspace = false
    if (isDisabled) {
      this.applyMask('')
      return
    }
    this.applyMask(this.inputValue)
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    let value = control.value
    if (!value) {
      this.maskError.emit(false)
      return null
    }

    if (this.sanitizationMask) {
      const regex = new RegExp(this.sanitizationMask, 'g')
      value = value.replace(regex, '')
    }

    if (this.validationMask) {
      const regex = new RegExp(this.validationMask, 'g')
      if (!regex.test(value)) {
        this.maskError.emit(true)
        return { patternInvalid: true }
      }
    }
    this.maskError.emit(false)
    return null
  }

  private applyMask(value: any) {
    if (this.backspace) {
      this.formElement.value = value
      this.onChange(this.clearMask(value))
      this.setInputValue(value)
      return
    }

    if (value && this.displayMask && this.displayMask[0] && this.displayMask[1] && this.displayMask[2]) {
      const regex = new RegExp(this.displayMask[0], 'g')
      value = this.clearMask(value)
      value = value.substr(0, parseInt(this.displayMask[2], 10))
      value = value.replace(regex, this.displayMask[1]) || ''
      value = value.trim()
    }

    this.formElement.value = value
    this.onChange(this.clearMask(value))
    this.setInputValue(value)
  }

  private clearMask(value: any): any {
    if (this.sanitizationMask && value) {
      const regex = new RegExp(this.sanitizationMask, 'g')
      value = value.replace(regex, '')
    }
    return value
  }

  private setInputValue(value: any): void {
    if (value) {
      this.inputValue = this.clearMask(value)
    }
  }
}
