import { Component, Input, ElementRef } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { TranslateHelper } from 'src/app/shared/helpers/translate.helper'
import { ValidatorHelper } from 'src/app/shared/helpers/validator.helper'
import { InputSquidComponent } from 'src/app/shared/components/input-sq/input-sq.component'

@Component({
  selector: 'input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  providers: [ValidatorHelper, TranslateHelper],
})
export class InputDateComponent extends InputSquidComponent {
  @Input() override type: 'date' | 'datetime-local' | 'month' = 'date'
  @Input() override min = '0001-01-01T00:00'
  @Input() override max = '9999-12-31T23:59'
  @Input() step?: number
  @Input() override hasTimeout = true
  @Input() showPickerOnClick = false
  @Input() override set value(v: any) {
    if (this.type === 'date') {
      this.thisValue = new Date(v)
    } else if (this.type === 'datetime-local') {
      const dateObject = new Date(v)
      this.thisValue =
        `${dateObject.getFullYear()}-` +
        `${dateObject.getMonth() + 1 < 10 ? '0' + (dateObject.getMonth() + 1) : dateObject.getMonth() + 1}-` +
        `${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T` +
        `${dateObject.getHours() < 10 ? '0' + dateObject.getHours() : dateObject.getHours()}:` +
        `${dateObject.getMinutes() < 10 ? '0' + dateObject.getMinutes() : dateObject.getMinutes()}`
    } else if (this.type === 'month') {
      this.thisValue = `${v.split('-')[0]}-` + `${v.split('-')[1]}`
    } else {
      this.thisValue = v
    }
  }

  override timeOutInputTime = 1000

  constructor(
    public override translate: TranslateService,
    public override validatorHelper: ValidatorHelper,
    public override translateHelper: TranslateHelper,
    public override element: ElementRef
  ) {
    super(translate, validatorHelper, translateHelper, element)
    this.nativeElement = element.nativeElement
    this.autocomplete = this.autocomplete || this.name
  }

  override validate(isBlur = false) {
    if (this.externalError) {
      this.error = false
    } else if (!!this.required && (!this.thisValue || this.thisValue.length < 1) && this.thisValue !== 0) {
      this.translate
        .stream('formErrors.required')
        .subscribe((text) => {
          this.error = text
        })
        .unsubscribe()
    } else if (this.thisValue && this.thisValue.length && !this.validatorHelper.date(this.thisValue)) {
      this.sharedValidType.emit(false)
      this.translate
        .stream('formErrors.date')
        .subscribe((text) => {
          this.error = text
        })
        .unsubscribe()
    } else {
      this.sharedValidType.emit(true)
      this.error = ''
    }

    if (isBlur) {
      this.sharedFocus.emit(false)
    }
  }

  override change(event: any): void {
    const valueAsDate = event?.target?.valueAsDate || ''
    const value = event?.target?.value || ''
    this.sharedFocus.emit(true)
    if (this.validatorHelper.date(valueAsDate || value)) {
      if (this.type === 'date') {
        let date = new Date(valueAsDate)
        date = new Date(date.toUTCString().slice(0, -4))
        date.setHours(0, 0, 0, 0)
        event = date.toISOString()
        this.thisValue = valueAsDate
      }
      if (this.type === 'datetime-local') {
        event = new Date(value).toISOString()
        this.thisValue = value
      }
      if (this.type === 'month') {
        event = new Date(value).toISOString()
        this.thisValue = value
      }
    } else {
      event = ''
      this.thisValue = ''
    }
    this.emit(event)
    this.validate()
  }

  override keyDown(event: any) {
    this.sharedKeyPress.emit(event)
  }

  override clickIcon(event: any) {
    this.emitExternalIconClick.emit(event)
  }

  focus(event: any) {
    if(this.showPickerOnClick) {
      event.target.showPicker()
    }
  }
}
