import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AppComponent } from './app.component'
import { DefaultLayoutComponent } from './shared/layouts/default/default.component'

const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        component: AppComponent,
      },
    ],
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule),
    runGuardsAndResolvers: 'always',
    canActivate: [],
  },
  {
    path: 'consultants',
    loadChildren: () => import('./modules/consultants/consultants.module').then((m) => m.ConsultantsModule),
    runGuardsAndResolvers: 'always',
    canActivate: [],
  },
  {
    path: 'reports',
    loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule),
    runGuardsAndResolvers: 'always',
    canActivate: [],
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
    runGuardsAndResolvers: 'always',
    canActivate: [],
  },
  {
    path: 'manage-trainings',
    loadChildren: () =>
      import('./modules/manage-consultant-trainings/manage-consultant-trainings.module').then((m) => m.ManageConsultantTrainingsModule),
    runGuardsAndResolvers: 'always',
    canActivate: [],
  },
  { path: '**', redirectTo: '/consultants', pathMatch: 'full' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
