import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

/**
 * Responsible for starting the translation class,
 * it should be used on all components that need translation
 */
@Injectable({
  providedIn: 'root',
})
export class TranslateHelper {
  constructor(public translate: TranslateService) {
    this.translate.addLangs(['en', 'pt', 'es'])
    this.translate.setDefaultLang('en')
  }

  setLanguageByBrowser(): void {
    const browserLang: string = this.translate.getBrowserLang() || 'en'
    let language = 'en'

    if (browserLang.match(/pt/)) {
      language = 'pt'
    }

    if (browserLang.match(/es/)) {
      language = 'es'
    }

    this.changeLanguage(language)
  }

  changeLanguage(lang: string) {
    this.translate.use(lang)
    const html = document.getElementsByTagName('html')[0]
    html.setAttribute('lang', lang === 'pt' ? 'pt-br' : lang)
  }

  getLanguage() {
    const html = document.getElementsByTagName('html')[0]
    return html.getAttribute('lang')
  }
}
