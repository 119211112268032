import { isPlatformServer } from '@angular/common'
import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core'
declare const Toast: any

@Injectable({
  providedIn: 'root',
})
export class ToastHelper {
  public toast: any = {
    success: (message: string, config: any) => this.toastLogSrr(message, config),
    error: (message: string, config: any) => this.toastLogSrr(message, config),
    inverted: (message: string, config: any) => this.toastLogSrr(message, config),
    info: (message: string, config: any) => this.toastLogSrr(message, config),
    warning: (message: string, config: any) => this.toastLogSrr(message, config),
    grayscale: (message: string, config: any) => this.toastLogSrr(message, config),
    custom: (message: string, config: any) => this.toastLogSrr(message, config),
    default: (message: string, config: any) => this.toastLogSrr(message, config),
    show: (message: string, config: any) => this.toastLogSrr(message, config),
  }

  isServer = isPlatformServer(this.platformId)

  constructor(@Inject(PLATFORM_ID) private platformId: InjectionToken<any>) {
    if (!this.isServer) {
      this.toast = Toast
    }
  }

  toastLogSrr(message: string, config: any) {
    return {
      message,
      config,
    }
  }
}
