<div
  class="wrapper-all-inside-input"
  [ngClass]="{
    'mb-3': marginBottom
  }"
>
  <label
    *ngIf="label && label.length > 0"
    class="label"
    [ngClass]="{
      error: (externalError && externalError !== '') || (error && error !== ''),
      disabled: disabled,
      readonly: readonly,
      small: small
    }"
    [for]="id"
    [innerHtml]="label | safeHtml"
  ></label>
  <div
    class="no-padding wrapper-input wrapper-input-squid"
    [ngClass]="{
      error: (externalError && externalError !== '') || (error && error !== ''),
      disabled: disabled,
      readonly: readonly
    }"
  >
    <span class="input-group-text" *ngIf="leftLabel">
      <ng-container *ngTemplateOutlet="leftLabel"></ng-container>
    </span>
    <input
      class="col input has-icon-extra-padding"
      [ngStyle]="{
        'background-color': backgroundColor
      }"
      [ngClass]="{
        'has-icon': error || externalError,
        error: (externalError && externalError !== '') || (error && error !== ''),
        disabled: disabled,
        readonly: readonly,
        'has-icon-external': externalIcon,
        small: small
      }"
      [id]="id || ''"
      *ngIf="type === 'date'"
      type="date"
      [name]="name"
      [step]="step"
      [placeholder]="placeholder || ''"
      [required]="required"
      [disabled]="disabled"
      [readonly]="readonly"
      [min]="min || ''"
      [max]="max || ''"
      (change)="change($event)"
      (focus)="focus($event)"
      [valueAsDate]="type === 'date' ? thisValue : null"
      (keydown)="keyDown($event)"
      [autocomplete]="autocomplete"
    />
    <input
      *ngIf="type !== 'date'"
      class="col input has-icon-extra-padding"
      [ngStyle]="{
        'background-color': backgroundColor
      }"
      [ngClass]="{
        'has-icon': error || externalError,
        error: (externalError && externalError !== '') || (error && error !== ''),
        disabled: disabled,
        readonly: readonly,
        'has-icon-external': externalIcon,
        small: small
      }"
      [id]="id || ''"
      [type]="type"
      [name]="name"
      [step]="step"
      [placeholder]="placeholder || ''"
      [required]="required"
      [disabled]="disabled"
      [readonly]="readonly"
      [min]="min || ''"
      [max]="max || ''"
      (change)="change($event)"
      [value]="thisValue | date: 'fullDate'"
      (keydown)="keyDown($event)"
      [autocomplete]="autocomplete"
    />
    <span class="input-group-text" *ngIf="rightLabel">
      <ng-container *ngTemplateOutlet="rightLabel"></ng-container>
    </span>
  </div>
  <span
    class="icon icon-external"
    *ngIf="externalIcon"
    [ngClass]="{
      'no-label': !label || !label.length,
      small: small
    }"
    (click)="clickIcon($event)"
  >
    <ng-container *ngTemplateOutlet="externalIcon"></ng-container>
  </span>
  <div class="box-validation box-invalid show" *ngIf="errorSpan">
    <ng-container *ngIf="externalError || error">
      <i class="fas fa-exclamation-triangle"></i>
    </ng-container>
    {{ externalError ? externalError : '' }}
    {{ error && !externalError ? error : '' }}
  </div>
</div>
