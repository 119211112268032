<div
  class="wrapper-selectors"
  [ngClass]="{
    checked: thisChecked,
    block: block,
    error: error,
    toggle: toggle,
    'toggle-switch': toggleSwitch,
    left: direction === 'left',
    right: direction === 'right',
    indeterminate: indeterminate
  }"
>
  <input
    [id]="id || timeStamp"
    [name]="name || timeStamp"
    [attr.type]="type"
    (change)="change($event)"
    [disabled]="disabled"
    [readonly]="readonly"
    [required]="required"
    [checked]="checked || thisChecked"
    [indeterminate]="indeterminate"
    [ngClass]="{ 'toggle-switch-checkbox': toggleSwitch }"
    #input
  />
  <label
    [for]="id || timeStamp"
    [ngClass]="{
      disabled: disabled,
      'hide-input': hideInput
    }"
    class="checkbox {{ type }}"
  ></label>
  <label [for]="id || timeStamp" *ngIf="labelRight" [ngClass]="{ block: block }">
    <ng-container
      [ngTemplateOutlet]="labelRight"
      [ngTemplateOutletContext]="{
        checked: thisChecked,
        value: value
      }"
    ></ng-container>
  </label>
  <label [for]="id || timeStamp" *ngIf="labelLeft" [ngClass]="{ block: block }">
    <ng-container
      [ngTemplateOutlet]="labelLeft"
      [ngTemplateOutletContext]="{
        checked: !thisChecked,
        value: value
      }"
    ></ng-container>
  </label>
</div>
