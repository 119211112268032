import { isPlatformBrowser } from '@angular/common'
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Output,
  PLATFORM_ID,
  ViewEncapsulation,
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import * as sanitizeHtml from 'sanitize-html'
import { ToastHelper } from 'src/app/shared/helpers/toast.helper'
import { EditorSqImageProperties } from './editor-sq-image-properties'
import { EditorSqEvents, validEvents } from './editor-sq.events'
import { EditorLangHelper } from 'src/app/shared/helpers/editor-lang.helper'

@Component({
  selector: 'editor-sq',
  template: ` <ng-template></ng-template>`,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./editor-sq.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorSqComponent),
      multi: true,
    },
  ],
})
export class EditorSqComponent extends EditorSqEvents implements AfterViewInit, OnDestroy, ControlValueAccessor {
  @Output() editorOut: EventEmitter<any> = new EventEmitter()
  @Input() tagName = 'textarea'
  @Input() id!: string
  @Input() defaultValue: string | undefined

  element!: HTMLElement
  editor: any

  private configPrivate: any = {
    language: 'en',
    useSearch: false,
    spellcheck: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    toolbarAdaptive: false,
    toolbarDisableStickyForMobile: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    disablePlugins: ['sticky', 'image-properties'],
    defaultActionOnPaste: 'insert-clear-html',
    buttons: ['bold', 'italic', 'underline', 'ul', 'ol', 'paragraph', 'video', 'hr', 'link'],
    toolbarButtonSize: 'large',
    placeholder: '',
    autofocus: false,
    tabIndex: 0,
    allowResizeTags: ['table'],
  }
  private onChangeCallback!: (_: any) => any
  private onTouchedCallback!: () => any

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private elementRef: ElementRef,
    private ngZone: NgZone,
    private translate: TranslateService,
    private toastHelper: ToastHelper,
    private editorLangHelper: EditorLangHelper
  ) {
    super()
    this.elementRef = elementRef
    this.ngZone = ngZone
    this.translate.get('editorPlaceholder.classContent').subscribe((result) => (this.configPrivate.placeholder = result))
    this.configPrivate.language = this.editorLangHelper.setLangInEditor(this.translate.currentLang)
  }

  get config() {
    return this.configPrivate
  }

  @Input()
  set config(source: any) {
    if (source) {
      this.configPrivate = Object.assign(this.configPrivate, source)
    }
    if (this.element) {
      this.resetEditor()
    }
  }

  get value(): string {
    if (this.editor) {
      return this.editor.getEditorValue()
    } else {
      return ''
    }
  }

  set value(v: string) {
    if (this.editor) {
      this.editor.setEditorValue(v || '')
    } else {
      this.defaultValue = v
    }
  }

  createElement() {
    const tagName = typeof this.tagName === 'string' ? this.tagName : 'textarea'
    this.element = document.createElement(tagName)
    if (this.element) {
      this.element.id = this.id
      this.elementRef.nativeElement.appendChild(this.element)
    }
  }

  async resetEditor() {
    this.editor.destruct()
    await this.createEditor()
  }

  async ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId) && !this.element) {
      this.createElement()
      await this.createEditor()
    }
  }

  async createEditor() {
    const editorModule: any = await require('jodit')

    this.ngZone.runOutsideAngular(() => {
      editorModule.Jodit.defaultOptions.controls.paragraph.list = {
        p: 'Normal',
        h2: 'Heading 2',
        h3: 'Heading 3',
        blockquote: 'Quote',
      }

      editorModule.Jodit.defaultOptions.controls.image = {
        exec: async () => {

        },
      }

      editorModule.Jodit.defaultOptions.controls.source = {
        popup: (editor: any) => {
          const button = new editorModule.Jodit.modules.UIButton(editor)
          button.state.status = 'primary'
          button.state.text = 'Insert'
          const byCode: any = new editorModule.Jodit.modules.UIForm(editor, [
            new editorModule.Jodit.modules.UIBlock(editor, [
              new editorModule.Jodit.modules.UITextArea(editor, {
                name: 'code',
                required: true,
                label: 'Embed code',
              }),
            ]),
            new editorModule.Jodit.modules.UIBlock(editor, [button.onAction(() => byCode.submit())]),
          ])
          const insertCode = (code: string) => {
            editor.s.restore()
            editor.s.insertHTML(code)
            close()
          }

          editor.s.save()

          byCode.onSubmit((data: any) => {
            insertCode(data.code)
          })

          return byCode.container
        },
        tags: ['iframe'],
        tooltip: 'Insert format block',
      }

      editorModule.Jodit.defaultOptions.controls.video = {
        popup: (editor: any) => {
          const button = new editorModule.Jodit.modules.UIButton(editor)
          button.state.status = 'primary'
          button.state.text = 'Insert'
          const byLink: any = new editorModule.Jodit.modules.UIForm(editor, [
            new editorModule.Jodit.modules.UIBlock(editor, [
              new editorModule.Jodit.modules.UIInput(editor, {
                name: 'url',
                required: true,
                label: 'URL',
                placeholder: 'https://',
                validators: ['url'],
              }),
            ]),
            new editorModule.Jodit.modules.UIBlock(editor, [button.onAction(() => byLink.submit())]),
          ])

          const insertCode = (code: string) => {
            editor.s.restore()
            code = `<div class="video-container">${code}<div/>`
            editor.s.insertHTML(code)
            close()
          }

          editor.s.save()

          byLink.onSubmit((data: any) => {
            insertCode(editorModule.Jodit.modules.Helpers.convertMediaUrlToVideoEmbed(data.url))
          })

          return byLink.container
        },

        tags: ['iframe'],
        tooltip: 'Insert youtube/vimeo video',
      }

      editorModule.Jodit.defaultOptions.popup.img = editorModule.Jodit.defaultOptions.popup.img.filter(
        (item: any) => item.name === 'delete' || item.name === 'pencil'
      )

      editorModule.Jodit.defaultOptions.popup.jodit = editorModule.Jodit.defaultOptions.popup.jodit.filter(
        (item: any) => item.name === 'bin'
      )

      editorModule.Jodit.defaultOptions.popup.a = editorModule.Jodit.defaultOptions.popup.a.filter(
        (item: any) => item.name === 'eye' || item.name === 'link' || item === 'unlink'
      )

      editorModule.Jodit.defaultOptions.link.modeClassName = ''
      editorModule.Jodit.defaultOptions.image.useImageEditor = false
      editorModule.Jodit.defaultOptions.image.editBorderRadius = false
      editorModule.Jodit.defaultOptions.image.editMargins = false
      editorModule.Jodit.defaultOptions.image.editClass = false
      editorModule.Jodit.defaultOptions.image.editStyle = false
      editorModule.Jodit.defaultOptions.image.editId = false
      editorModule.Jodit.defaultOptions.image.editAlign = false
      editorModule.Jodit.defaultOptions.image.editSize = false
      editorModule.Jodit.defaultOptions.link.noFollowCheckbox = false
      editorModule.Jodit.defaultOptions.link.processVideoLink = false
      editorModule.Jodit.defaultOptions.link.processPastedLink = false

      this.editor = new editorModule.Jodit(this.element, this.config)
    })

    if (this.defaultValue) {
      this.editor.value = this.defaultValue
    }

    this.editor.e.on('processPaste.link', (ignore: ClipboardEvent, html: string): HTMLAnchorElement | void => {
      if (editorModule.Jodit.modules.Helpers.isURL(html)) {
        let embed = editorModule.Jodit.modules.Helpers.convertMediaUrlToVideoEmbed(html)
        if (embed !== html) {
          embed = `<div class="video-container">${embed}<div/>`
          return this.editor.createInside.fromHTML(embed) as HTMLAnchorElement
        }
      }
    })

    this.editor.e.on('processPaste.paste', (event: ClipboardEvent, text: string): string => {
      if (text) {
        this.editor.e.stopPropagation('paste.paste')
        event.preventDefault()
        event.stopPropagation()
        return this.getSafeHTML(text)
      }
      return text
    })

    const imageProperties = new EditorSqImageProperties(this.editor, editorModule)

    this.editor.e.on('openImageProperties', (image: HTMLImageElement) => {
      imageProperties.image = image
      imageProperties.open()
      this.editor.e.stopPropagation('openImageProperties')
    })

    this.editor.events
      .on('change', (value: string) => {
        if (typeof this.onChangeCallback === 'function') {
          this.ngZone.run(() => this.onChangeCallback(value))
        }
      })
      .on('blur', () => {
        if (typeof this.onTouchedCallback === 'function') {
          this.ngZone.run(() => this.onTouchedCallback())
        }
      })

    validEvents.forEach((eventName) => {
      const eventEmitter: EventEmitter<any> = this[eventName]
      if (eventEmitter.observers.length > 0) {
        let eventNameInJodit = eventName.substring(2)
        eventNameInJodit = eventNameInJodit.substr(0, 1).toLowerCase() + eventNameInJodit.substring(1)
        this.editor.events.on(
          eventNameInJodit,
          this.ngZone.run(
            () =>
              (...args: any[]) =>
                eventEmitter.emit({
                  args,
                  editor: this.editor,
                })
          )
        )
      }
    })
    this.editorOut.emit(this.editor)
  }

  ngOnDestroy() {
    if (this.editor) {
      this.editor.destruct()
    }
  }

  writeValue(v: any): void {
    this.value = v
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn
  }

  registerOnTouched(fn: () => any): void {
    this.onTouchedCallback = fn
  }

  setDisabledState(isDisabled: boolean): void {
    this.editor?.setReadOnly(isDisabled)
  }

  private getSafeHTML(html: string): string {
    return sanitizeHtml(html, {
      allowedTags: [
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'p',
        'blockquote',
        'strong',
        'em',
        'u',
        'ul',
        'ol',
        'li',
        'a',
        'hr',
        'iframe',
        'img',
        'del',
        'ins',
        'pre',
        'cite',
      ],
      allowedAttributes: {
        a: ['href', 'target', 'rel'],
        img: ['src', 'alt', 'width', 'height'],
      },
      transformTags: {
        h1: 'h2',
        a: sanitizeHtml.simpleTransform('a', { rel: 'noreferrer' }, true),
      },
    })
  }
}
