import {
  CalendarOptions,
  DatesSetArg,
  DidMountHandler,
  EventClickArg,
  EventDropArg,
  EventInput,
  EventMountArg,
  FormatterInput,
  PluginDef
} from "@fullcalendar/core"
import { DateFormatter } from "@fullcalendar/core/internal"
import { DateClickArg, EventResizeDoneArg } from "@fullcalendar/interaction"

export class CalendarOptionsModel implements CalendarOptions {
  slotMinTime: string
  slotMaxTime: string
  timeZone: string
  expandRows: boolean
  plugins: Array<PluginDef> = []
  initialView: string
  headerToolbar = {
    left: 'prev',
    center: 'title',
    right: 'today, next',
  }
  titleFormat: FormatterInput = {}
  dayHeaderFormat: FormatterInput | DateFormatter
  allDaySlot: boolean
  buttonText = {
    today: 'Hoje',
  }
  locale: string
  themeSystem: string
  nowIndicator: boolean
  navLinks: boolean
  datesSet: ((arg: DatesSetArg) => void)
  dateClick: ((arg: DateClickArg) => void)
  eventClick: ((arg: EventClickArg) => void)
  eventDidMount: DidMountHandler<EventMountArg>
  eventDrop: ((arg: EventDropArg) => void)
  eventResize: ((arg: EventResizeDoneArg) => void)
  editable: boolean
  selectable: boolean
  selectOverlap: boolean
  events: Array<EventInput>

  constructor(
    plugins: Array<PluginDef>,
    events: Array<EventInput>,
    datesSet: ((arg: DatesSetArg) => void),
    dateClick: ((arg: DateClickArg) => void),
    eventClick: ((arg: EventClickArg) => void),
    eventDidMount: DidMountHandler<EventMountArg>,
    eventDrop: ((arg: EventDropArg) => void),
    eventResize: ((arg: EventResizeDoneArg) => void),
    titleFormat: FormatterInput,
    dayHeaderFormat: FormatterInput | DateFormatter,
    todayLabel = 'Hoje',
    timeZone = 'America/Sao_Paulo',
    locale = 'pt-BR',
    slotMinTime = '06:00',
    slotMaxTime = '24:00',
    initialView = 'timeGridWeek',
    allDaySlot = false,
    themeSystem = 'united',
    nowIndicator = true,
    navLinks = true,
    editable = true,
    selectable = true,
    selectOverlap = false,
    expandRows = true
  ) {
    this.plugins = plugins
    this.events = events
    this.datesSet = datesSet
    this.dateClick = dateClick
    this.eventClick = eventClick
    this.eventDidMount = eventDidMount
    this.eventDrop = eventDrop
    this.eventResize = eventResize
    this.buttonText.today = todayLabel
    this.titleFormat = titleFormat
    this.dayHeaderFormat = dayHeaderFormat
    this.timeZone = timeZone
    this.locale = locale
    this.slotMinTime = slotMinTime
    this.slotMaxTime = slotMaxTime
    this.initialView = initialView,
    this.allDaySlot = allDaySlot
    this.themeSystem = themeSystem
    this.nowIndicator = nowIndicator
    this.navLinks = navLinks
    this.editable = editable
    this.selectable = selectable
    this.selectOverlap = selectOverlap
    this.expandRows = expandRows
  }
}
