import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { InputFileComponent } from 'src/app/shared/components/input-file/input-file.component'
import { ColorsHelper } from 'src/app/shared/helpers/color.helper'
import { TranslateHelper } from 'src/app/shared/helpers/translate.helper'
import { ValidatorHelper } from 'src/app/shared/helpers/validator.helper'

@Component({
  selector: 'input-cover',
  templateUrl: './input-cover.component.html',
  styleUrls: ['./input-cover.component.scss'],
})
export class InputCoverComponent extends InputFileComponent implements OnInit {
  @Input() coverUrl?: string
  @Input() inputMessage = ''
  @Input() height = '200px'
  @Output() deleteImageEvent: EventEmitter<any> = new EventEmitter()

  constructor(
    public override translate: TranslateService,
    public override validatorHelper: ValidatorHelper,
    public override translateHelper: TranslateHelper,
    public override element: ElementRef,
    public override colorsHelper: ColorsHelper
  ) {
    super(translate, validatorHelper, translateHelper, element, colorsHelper)
  }

  override ngOnInit(): void {
    this.type = 'file'
  }

  override change(value: any): void {
    this.value = value
    this.validate()
    if (value && value.length > 0) {
      const response: any = {}
      const reader = new FileReader()
      reader.readAsDataURL(value[0])
      response.stream = value[0]
      response.fileName = value[0].name
      reader.onload = (thisEvent: any) => {
        response.url = thisEvent.target.result
        this.coverUrl = response.url
        this.sharedValue.emit(response)
      }
    } else {
      this.sharedValue.emit(value)
    }
  }

  deleteImage() {
    this.value = ''
    this.coverUrl = ''
    this.sharedValue.emit(null)
    this.deleteImageEvent.emit(true)
  }
}
