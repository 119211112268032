<sq-modal (modalClose)="close()" [open]="open" modalSize="md" [buttonClose]="false">
  <div class="display-grid my-6">
    <sq-loader *ngIf="loading" class="display-block text-center align-self-center" size="big"></sq-loader>
    <p *ngIf="loading" class="text-center mt-2">{{ 'exportReports.initProcess' | translate }}</p>
    <div *ngIf="!loading && !completed" class="display-grid">
      <sq-progress-bar [value]="progress" [striped]="true" color="green"></sq-progress-bar>
      <small class="text-center">{{ 'exportReports.await' | translate }}</small>
    </div>
    <div *ngIf="!loading && completed" class="completed">
      <i class="fa-solid fa-check-circle text-success"></i>
      <h3 class="ml-2">{{ 'exportReports.success' | translate }}</h3>
      <sq-button color="primary" class="ml-2" (emitClick)="close()">{{ 'close' | translate }}</sq-button>
    </div>
  </div>
</sq-modal>
