import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class EditorLangHelper {
  setLangInEditor(lang: string) {
    switch (lang) {
      case 'es': {
        return 'es'
      }
      case 'pt': {
        return 'pt_br'
      }
      default: {
        return 'en'
      }
    }
  }
}
