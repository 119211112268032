import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core'
import interactionPlugin, { DateClickArg, EventResizeDoneArg } from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import luxonPlugin2 from '@fullcalendar/luxon2'
import { CalendarEvent } from 'src/app/shared/models/calendar-event.model'
import { CalendarOptionsModel } from 'src/app/shared/models/calendar-options-model'
import { TranslateService } from '@ngx-translate/core'
import { lastValueFrom, Subscription } from 'rxjs'
import { EventInput, EventClickArg, DatesSetArg, EventMountArg, EventDropArg } from '@fullcalendar/core'
import { EventImpl } from '@fullcalendar/core/internal'
import { DateHelper } from 'src/app/shared/helpers/date.helper'
import { UserService } from 'src/app/shared/services/user/user.service'
import { ConsultantData } from 'src/app/shared/models/consultante-data'
import { UserPermissions } from 'src/app/shared/enums/user-permissions.enum'

@Component({
  selector: 'calendar-sq',
  templateUrl: './calendar-sq.component.html',
  styleUrls: ['./calendar-sq.component.scss']
})
export class CalendarSquidComponent implements OnInit, OnChanges, OnDestroy {
  @Input() calendarEvents: Array<CalendarEvent> = []
  @Output() emitDateSet: EventEmitter<{start: string, end: string}> = new EventEmitter()
  @Output() emitDateClick: EventEmitter<Date> = new EventEmitter()
  @Output() emitEventClick: EventEmitter<{ id: string, status: string, startDate: Date | null, action?: string }> = new EventEmitter()
  @Output() emitDateDrop: EventEmitter<EventDropArg> = new EventEmitter()
  @Output() emitDateResize: EventEmitter<EventResizeDoneArg> = new EventEmitter()
  @ViewChild('fullCalendar', { static: true }) fullCalendar: any

  events: Array<EventInput> = []
  calendarOptions: CalendarOptionsModel | undefined
  rightClickMenuPositionX = 0
  rightClickMenuPositionY = 0
  showContextMenu = false
  clickedEvent: EventMountArg | undefined
  selectedEvent: EventImpl | undefined
  pointerdown = false
  authenticateObservable!: Subscription
  user: ConsultantData | null = null

  @HostListener('document:click')
  documentClick(): void {
    this.showContextMenu = false
  }

  constructor(
    public translate: TranslateService,
    public dateHelper: DateHelper,
    public userService: UserService
  ) { }

  async ngOnInit(): Promise<void> {
    this.authenticateObservable = this.userService.currentUserSubject?.subscribe(
      async (user) => {
        this.user = user
      }
    )
    this.calendarOptions = new CalendarOptionsModel(
      [interactionPlugin, timeGridPlugin, bootstrapPlugin, luxonPlugin2],
      this.events,
      this.handleDatesSet.bind(this),
      this.handleDateClick.bind(this),
      this.handleDateView.bind(this),
      (event: EventMountArg) => {
        if (this.user?.dashboard_permissions?.find((perm) => perm === UserPermissions.Trainer)?.length) {
          event.el.addEventListener('contextmenu',
            (menu: MouseEvent) => {
              this.showContextMenu = false
              menu.preventDefault()
              this.clickedEvent = event
            }
          )
        }
        const node = event.el.parentElement
        node?.classList.add('event-card')
        if (['rejected', 'no_show', 'canceled', 'processed', 'removed'].includes(event.event.extendedProps?.status)) {
          const node = event.el.parentElement
          node?.classList.add('rejected-event-card')
        }
      },
      this.handleDateDrop.bind(this),
      this.handleDateResize.bind(this),
      { year: 'numeric', month: 'long' },
      { day: 'numeric', weekday: 'short' },
      await lastValueFrom(this.translate.get('today')),
      'America/Sao_Paulo'
    )
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes?.calendarEvents?.currentValue) {
      this.events = []
      this.events = changes?.calendarEvents?.currentValue ?? []
      this.calendarOptions = new CalendarOptionsModel(
        [interactionPlugin, timeGridPlugin, bootstrapPlugin, luxonPlugin2],
        this.events,
        this.handleDatesSet.bind(this),
        this.handleDateClick.bind(this),
        this.handleDateView.bind(this),
        (event: EventMountArg) => {
          if (this.user?.dashboard_permissions?.find((perm) => perm === UserPermissions.Trainer)?.length) {
            event.el.addEventListener('contextmenu',
              (menu: MouseEvent) => {
                this.showContextMenu = false
                menu.preventDefault()
                this.clickedEvent = event
              }
            )
          }
          const node = event.el.parentElement
          node?.classList.add('event-card')
          if (['rejected', 'no_show', 'canceled', 'processed', 'removed'].includes(event.event.extendedProps.status)) {
            const node = event.el.parentElement
            node?.classList.add('rejected-event-card')
          }
        },
        this.handleDateDrop.bind(this),
        this.handleDateResize.bind(this),
        { year: 'numeric', month: 'long' },
        { day: 'numeric', weekday: 'short' },
        await lastValueFrom(this.translate.get('today')),
        'America/Sao_Paulo'
      )
    }
  }

  ngOnDestroy(): void {
    this.authenticateObservable?.unsubscribe()
  }

  mouserEnter(eventId: string) {
    const eventCard =
      this.fullCalendar?.nativeElement?.querySelector(`.fc-timegrid-event-harness.fc-timegrid-event-harness-inset:has(a.event-${eventId})`)
    eventCard.style.zIndex = "2"
  }

  mouserLeave(eventId: string) {
    const eventCard =
      this.fullCalendar?.nativeElement?.querySelector(`.fc-timegrid-event-harness.fc-timegrid-event-harness-inset:has(a.event-${eventId})`)
    eventCard.style.zIndex = "1"
  }

  handleDatesSet(event: DatesSetArg) {
    this.emitDateSet.emit(
      {
        start: this.dateHelper.setTimeOfISOStringDate(event.start.toISOString(), '00:00:00').toISOString(),
        end: this.dateHelper.setTimeOfISOStringDate(event.end.toISOString(), '23:59:00').toISOString()
      }
    )
  }

  handleDateClick(event: DateClickArg) {
    this.emitDateClick.emit(new Date(event.date))
  }

  handleDateView(arg: EventClickArg, action = '') {
    this.emitEventClick.emit(
      {
        id: arg.event.id,
        status: arg.event.extendedProps.status,
        startDate: arg.event.start,
        action
      }
    )
  }

  viewEvent(action: string) {
    document.body.style.overflow = 'hidden'
    this.emitEventClick.emit(
      {
        id: this.selectedEvent?.id || '',
        status: this.selectedEvent?.extendedProps.status,
        startDate: this.selectedEvent?.start || null,
        action
      }
    )
  }

  onRightClick(arg: any) {
    if( this.clickedEvent && this.clickedEvent?.event?.extendedProps?.status !== 'block' ) {
      this.changeContextMenuPosition(arg.clientX, arg.clientY)
      this.selectedEvent = this.clickedEvent?.event
    } else {
      this.showContextMenu = false
      this.selectedEvent = undefined
    }
    this.clickedEvent = undefined
  }

  getRightClickMenuStyle() {
    document.body.style.overflow = 'hidden'
    return {
      position: 'fixed',
      left: `${this.rightClickMenuPositionX}px`,
      top: `${this.rightClickMenuPositionY}px`
    }
  }

  changeContextMenuPosition(clientX: number, clientY: number) {
    const calendarWidth = this.fullCalendar.nativeElement.offsetWidth
    const calendarHeight = this.fullCalendar.nativeElement.offsetHeight

    if (clientY + 80 > calendarHeight) {
        this.rightClickMenuPositionY = clientY - 80
    } else {
        this.rightClickMenuPositionY = clientY
    }
    if (clientX + 205 > calendarWidth) {
        this.rightClickMenuPositionX = clientX - 205
    } else {
        this.rightClickMenuPositionX = clientX
    }
    this.showContextMenu = true
  }

  handleDateDrop(arg: EventDropArg) {
    this.emitDateDrop.emit(arg)
  }

  handleDateResize(arg: EventResizeDoneArg) {
    this.emitDateResize.emit(arg)
  }
}
