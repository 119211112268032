import { DOCUMENT} from '@angular/common'
import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'

@Component({
  selector: 'overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent implements OnInit, OnChanges {
  @Input() open?: boolean
  @Input() overlayClass?: string
  @Input() backdropClass?: string
  @Input() overlayDirection: 'right' | 'left' = 'right'
  @Input() backdrop = 'static'
  @Input() width = '475px'
  @Input() maxWidth = '100%'
  @Input() headerColor = 'var(--background)'
  @Input() headerHeight = '65px'
  @Input() footerColor = 'var(--background)'
  @Input() bodyColor = 'var(--background)'
  @Input() backdropColor = 'var(--background)'
  @Input() showClose = true
  @Input() borderless = false
  @Input() contentCentralized = false
  @Input() id = `random-id-${(1 + Date.now() + Math.random()).toString().replace('.', '')}`

  @Output() openChange: EventEmitter<any> = new EventEmitter()
  @Output() closeChange: EventEmitter<any> = new EventEmitter()
  @Output() leftPress: EventEmitter<any> = new EventEmitter()
  @Output() rightPress: EventEmitter<any> = new EventEmitter()

  @ViewChild('overlay') overlay: ElementRef | null = null

  @ContentChild('headerOverlay') headerTemplate?: TemplateRef<any> | null = null
  @ContentChild('footerOverlay') footerTemplate?: TemplateRef<any> | null = null

  modals: HTMLCollectionOf<Element> | undefined
  modalNumber = 0
  hasHeader = false
  hasFooter = false
  document: any = null
  enableBackdropClick = false
  styleId = ''
  finishOpening = false

  constructor(
    private router: Router,
    @Inject(DOCUMENT) public documentImported: Document,
  ) {
    this.onKeydown = this.onKeydown.bind(this)
    this.document = documentImported || document
  }

  @HostListener('document:click', ['$event'])
  backdropClick(event: any) {
    if (this.backdrop === 'static' || !this.overlay || !this.open || !this.enableBackdropClick) {
      return
    }
    const modalDialog = this.overlay.nativeElement.firstElementChild
    if (!modalDialog?.contains(event.target)) {
      this.toCloseOverlay()
    }
  }

  ngOnInit() {
    this.styleId = `overlay-${new Date().getTime()}-${Math.random().toString(36).substring(7)}`
    this.doCssWidth()
    this.router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd) {
        this.toCloseOverlay()
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('width')) {
      this.doCssWidth()
    }
    if (changes.hasOwnProperty('open')) {
      const body = this.document.getElementsByTagName('body')[0]
      const backdrop = this.document.getElementById('modal-backdrop') || this.document.createElement('div')
      if (this.open && this.overlay) {
        this.hasFooter = !!this.footerTemplate
        this.hasHeader = !!this.headerTemplate
        this.modals = this.document.getElementsByClassName('modal open')
        body.classList.add('block')
        this.overlay.nativeElement.style.display = 'flex'
        window.addEventListener('keydown', this.onKeydown)
        setTimeout(() => {
          this.modalNumber = this.modals?.length || 0
          if (this.modalNumber === 1) {
            backdrop.setAttribute('id', 'modal-backdrop')
            backdrop.setAttribute('class', 'modal-backdrop show')
            body.appendChild(backdrop)
          }
          this.enableBackdropClick = true
          this.finishOpening = true
        })
      } else if (this.overlay) {
        this.openChange.emit(false)
        this.closeChange.emit()
        this.finishOpening = false
        setTimeout(() => {
          if (this.overlay) {
            this.overlay.nativeElement.style.display = 'none'
          }
        })
        if (backdrop.parentNode && this.modalNumber === 1) {
          backdrop.parentNode.removeChild(backdrop)
          body.classList.remove('block')
        }
        this.enableBackdropClick = false
        window.removeEventListener('keydown', this.onKeydown)
      }
    }
  }

  doCssWidth() {
    const css = `
    .overlay.open .modal-dialog.opened {
      width: ${this.width};
      max-width: ${this.maxWidth};
    }
    `
    const head = this.document.getElementsByTagName('head')[0]
    let style = this.document.getElementById(this.styleId)
    if (!style) {
      style = this.document.createElement('style')
      style.setAttribute('id', this.styleId)
      style.appendChild(this.document.createTextNode(css))
      head.appendChild(style)
    } else {
      style.innerHTML = ''
      style.appendChild(this.document.createTextNode(css))
    }
  }

  onKeydown(event: any) {
    if (this.open) {
      this.modals = this.document.getElementsByClassName('modal')
      if (this.modals?.length === this.modalNumber) {
        this.events(event.keyCode)
      }
    }
  }

  events(key: number) {
    switch (key) {
      case 27:
        this.openChange.emit(false)
        this.closeChange.emit()
        break
      case 37:
        this.leftPress.emit()
        break
      case 39:
        this.rightPress.emit()
        break
    }
  }

  toCloseOverlay() {
    if (this.overlay && this.open) {
      const body = this.document.getElementsByTagName('body')[0]
      const backdrop = this.document.getElementById('modal-backdrop') || this.document.createElement('div')
      this.openChange.emit(false)
      this.closeChange.emit()
      this.overlay.nativeElement.style.display = 'none'
      if (backdrop.parentNode && this.modalNumber === 1) {
        backdrop.parentNode.removeChild(backdrop)
        body.classList.remove('block')
      }
      window.removeEventListener('keydown', this.onKeydown)
      this.enableBackdropClick = false
    }
  }
}
