import { Component, Input, Output, EventEmitter, ContentChild, TemplateRef } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { TranslateHelper } from 'src/app/shared/helpers/translate.helper'
import { ValidatorHelper } from 'src/app/shared/helpers/validator.helper'

@Component({
  selector: 'select-sq',
  templateUrl: './select-sq.component.html',
  styleUrls: ['./select-sq.component.scss'],
  providers: [ValidatorHelper, TranslateHelper, TranslateService],
})
export class SelectSquidComponent {
  @Input() name = ''
  @Input() value: any = ''
  @Input() color = 'black'
  @Input() small = false
  @Input() colorLabel = 'black'
  @Input() id = ''
  @Input() label?: string
  @Input() labelType?: string
  @Input() disabled = false
  @Input() readonly = false
  @Input() required = false
  @Input() placeholder?: string
  @Input() externalError?: any
  @Input() validateIcons = true
  @Input() options: Array<{
    value: any
    label: string
    disabled?: boolean
  }> = []
  @Input() errorSpan = true
  @Input() loading = false
  @Input() fullLabel = false
  @Input() backgroundColor = 'white'
  @Input() simpleStyle = false
  @Input() marginBottom = true
  @Input() borderLess = false

  @Output() sharedValue: EventEmitter<any> = new EventEmitter()
  @Output() sharedFocus: EventEmitter<any> = new EventEmitter()
  @Output() emitExternalIconClick: EventEmitter<any> = new EventEmitter()

  @ContentChild('externalIcon', { static: true })
  externalIcon: TemplateRef<HTMLElement> | null = null
  @ContentChild('leftLabel', { static: true })
  leftLabel: TemplateRef<HTMLElement> | null = null
  @ContentChild('rightLabel', { static: true })
  rightLabel: TemplateRef<HTMLElement> | null = null

  error: any = false
  timeStamp = `random-id-${(1 + Date.now() + Math.random()).toString().replace('.', '')}`

  constructor(public translate: TranslateService, public validatorHelper: ValidatorHelper, public translateHelper: TranslateHelper) {
    this.loading = false
  }

  validate(isBlur = false) {
    const { required, value } = this

    if (this.externalError) {
      this.error = false
    } else if (!!required && (!value || value.length < 1)) {
      this.translate.stream('formErrors.required').subscribe((text) => {
        this.error = text
      })
    } else {
      this.error = ''
    }

    if (isBlur) {
      this.sharedFocus.emit(false)
    }
  }

  change(value: any): void {
    this.sharedFocus.emit(true)
    this.value = value
    this.sharedValue.emit(value)
    this.validate()
  }

  clickIcon(event: any) {
    this.emitExternalIconClick.emit(event)
  }
}
