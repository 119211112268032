import {
  Component,
  Input,
  AfterContentInit,
  AfterContentChecked,
  Output,
  EventEmitter,
  ContentChild,
  TemplateRef,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  HostListener,
  SimpleChanges,
  OnChanges,
} from '@angular/core'
import {
  animate,
  AnimationFactory,
  AnimationPlayer,
  AnimationBuilder,
  style
} from '@angular/animations'

@Component({
  selector: 'carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements AfterContentInit, AfterContentChecked, OnChanges {
  @Input() id?: string
  @Input() perPage = 1
  @Input() toScroll = 1
  @Input() showArrows = true
  @Input() showDots = false
  @Input() infinite = false // TODO
  @Input() centerMode = false
  @Input() itemHeight = '150px'
  @Input() hideOnEnd = false
  @Input() loaderButtons = false
  @Input() items: Array<any> = []
  @Input() format = true
  @Input() variableHeight = false
  @Input() dotsReadOnly = false

  @Output() slideNumber: EventEmitter<any> = new EventEmitter()
  @Output() initSlider: EventEmitter<boolean> = new EventEmitter()

  @ContentChild('itemLayout', { static: true })
  itemLayout: TemplateRef<any> | null = null
  @ContentChild('prevArrow', { static: true })
  prevArrow?: TemplateRef<any> | null = null
  @ContentChild('nextArrow', { static: true })
  nextArrow?: TemplateRef<any> | null = null
  @ContentChild('dots', { static: true })
  dots?: TemplateRef<any> | null = null

  @ViewChild('carousel', { static: true })
  carousel: ElementRef | null = null
  @ViewChild('wrapper', { static: true })
  wrapper: ElementRef | null = null
  @ViewChild('parent', { static: true })
  parent: ElementRef | null = null

  @ViewChildren('itens')
  itens: QueryList<ElementRef> | null = null

  /* Local Variables */
  currentSlide = 1
  init = false
  itemWidth = '150px'
  player: AnimationPlayer | null = null
  itensHtml: Array<ElementRef> = []

  timing = '350ms ease'
  timeoutInput: any
  /* Local Variables */

  constructor(
    private builder: AnimationBuilder
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkVisible()
    this.currentSlide = (this.items && this.currentSlide > this.items.length) ? this.items.length : this.currentSlide
    this.animate()
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.checkVisible()
      this.currentSlide = this.perPage
    })
  }

  async ngOnChanges(changes: SimpleChanges) {
    // eslint-disable-next-line
    if (changes.hasOwnProperty('itens')) {
      this.checkVisible()
    }
  }

  ngAfterContentChecked(): void {
    if (!this.init) {
      this.checkVisible()
    }

    if (this.itemWidth === '0px' && this.init) {
      this.itemWidth = this.wrapper?.nativeElement.getBoundingClientRect().width / this.perPage + 'px'
    }

    if (this.itemWidth !== (this.wrapper?.nativeElement.getBoundingClientRect().width / this.perPage + 'px')) {
      this.itemWidth = this.wrapper?.nativeElement.getBoundingClientRect().width / this.perPage + 'px'
    }
  }

  checkVisible() {
    this.itemWidth = this.wrapper?.nativeElement.getBoundingClientRect().width / this.perPage + 'px'
    if (this.itens && this.itens.length > 0) {
      if (!this.init) {
        this.currentSlide = this.toScroll
        this.itensHtml = this.itens.toArray()
        this.init = true
      }
    } else {
      this.currentSlide = 1
      this.init = false
    }
    this.initSlider.emit(this.init)
  }

  trackSlide(index: number) {
    return index
  }

  afterChange(action: string | number): void {
    this.slideNumber.emit({
      prev: this.currentSlide,
      next: this.currentSlide + 1,
      action
    })
  }

  buildAnimation(offset: any) {
    return this.builder.build([
      animate(this.timing, style({
        transform: `translateX(-${offset}px)`
      }))
    ])
  }

  public prev() {
    if (!this.loaderButtons) {
      this.afterChange('prev')
      if (this.currentSlide === this.toScroll) {
        return
      }
      this.currentSlide -= this.toScroll
      this.animate()
    }
  }

  public next() {
    if (!this.loaderButtons) {
      this.afterChange('next')
      if (this.currentSlide >= this.items.length) {
        return
      }
      this.currentSlide += this.toScroll
      this.animate()
    }
  }

  dotClick(index: number) {
    if (!this.dotsReadOnly) {
      this.afterChange(index)
      this.currentSlide = index + 1
      this.animate()
    }
  }

  swipe(event: any, name: string) {
    clearTimeout(this.timeoutInput)
    this.timeoutInput = setTimeout(() => {
      if (name === 'right') {
        this.prev()
      }
      if (name === 'left') {
        this.next()
      }
    }, 300)
  }

  animate() {
    const offset = (this.currentSlide - this.toScroll) * parseInt(this.itemWidth, 10)
    const myAnimation: AnimationFactory = this.buildAnimation((offset < 0) ? 0 : offset)
    this.player = myAnimation.create(this.carousel?.nativeElement)
    this.player.play()
  }
}
