import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core'
import { ReportSearchModel } from 'src/app/shared/models/report-search-model'
import { DateHelper } from '../../helpers/date.helper'
import { lastValueFrom } from "rxjs"
import { ToastHelper } from "src/app/shared/helpers/toast.helper"
import { TranslateService } from "@ngx-translate/core"
import { CategoriesInterface } from '../../models/categories.interface'

@Component({
  selector: 'report-search-form',
  templateUrl: './report-search-form.component.html',
  styleUrls: ['./report-search-form.component.scss']
})
export class ReportSearchFormComponent {

  @Input() title = ''
  @Input() loading = false
  @Input() value = new ReportSearchModel()
  @Input() disbled = false
  @Input() dateType: 'range' | 'month' = 'month'
  @Input() showSelectSubcategory = false

  @Output() sharedFormValue: EventEmitter<
    {
      name: string,
      startDate: string,
      endDate: string,
      date?: {
        startDate: string,
        endDate: string,
      },
      natura_trainings_category_id?: string,
      natura_trainings_subcategory_id?: string,
    }> = new EventEmitter()
  @Output() sharedResetForm: EventEmitter<void> = new EventEmitter()

  @ContentChild('rightButton', { static: true })
  rightButton: TemplateRef<HTMLElement> | null = null
  dateError = ''
  window = window
  showMoreOptions = false
  selectedCategory: CategoriesInterface
  selectedSubcategory: CategoriesInterface
  openSelectCategoryModal = false

  constructor(
    public dateHelper: DateHelper,
    public toastHelper: ToastHelper,
    public translate: TranslateService,
  ) {
    this.selectedCategory = this.resetCategory()
    this.selectedSubcategory = this.resetCategory()
  }

  parseMonth(month: string ): void {
    const year = month.split('-')[0]
    this.value.date.value = month
    if (year.length !== 4) {
      this.dateError = 'formErrors.date'
    } else {
      this.dateError = ''
    }
  }

  getFormValue(): void {
    if (this.dateType === 'range' && this.value.startDate.value && this.value.endDate.value) {
      if (new Date(this.value.startDate.value).getTime() > new Date(this.value.endDate.value).getTime()) {
        this.dateError = 'reportSearchForm.dateError'
        return
      }
      this.dateError = ''
      const endDate = new Date(`${this.value.endDate.value}T23:59:59.000Z`)
      this.sharedFormValue.emit(
        {
          name: this.value.name.value || '',
          startDate: `${this.value.startDate.value}T03:00:00.000Z`,
          endDate: this.dateHelper.addMinutes(endDate, 180).toISOString(),
          date: {
            startDate: `${this.value.startDate.value}T00:00:00.000Z`,
            endDate: `${this.value.endDate.value}T23:59:59.000Z`
          },
          natura_trainings_category_id: this.selectedCategory.id,
          natura_trainings_subcategory_id: this.selectedSubcategory.id,
        }
      )
    }
    if (this.dateType === 'month' && this.value.date.value) {
      const endDate = new Date(this.value.date.value)
      this.sharedFormValue.emit(
        {
          name: this.value.name.value || '',
          startDate: this.getStartDate(this.value.date.value).toISOString(),
          endDate: endDate.toISOString()
        }
      )
    }
  }

  getStartDate(date: string): Date {
    const year = Number(date.split('-')[0])
    const month = Number(date.split('-')[1]) - 1

    return new Date(month === 0 ? ((year - 1) + '-' + 12) : year + '-' + month)
  }

  maxRangeDate(date: Date, months: number): Date {
    const newDate = new Date(date)
    newDate.setMonth(date.getMonth() + months)
    return newDate
  }

  setValue(field: string, value: string): void {
    if (!this.value.startDate.value || !this.value.endDate.value) {
      this.value.startDate.value = value
      this.value.endDate.value = value
      return
    }

    const startDate = new Date(this.value.startDate.value)
    const endDate = new Date(this.value.endDate.value)
    let dateAdjustedByMonth: Date

    switch (field) {
      case 'startDate':
        dateAdjustedByMonth = this.maxRangeDate(new Date(value), 1)
        if (endDate.getTime() > dateAdjustedByMonth.getTime()) {
          this.value.endDate.value = dateAdjustedByMonth.toISOString().split("T")[0]
          this.dateUpdatedWarning()
        }
        this.value.startDate.value = value
        break

      case 'endDate':
        dateAdjustedByMonth = this.maxRangeDate(new Date(value), -1)
        if (startDate.getTime() < dateAdjustedByMonth.getTime()) {
          this.value.startDate.value = dateAdjustedByMonth.toISOString().split("T")[0]
        }
        this.value.endDate.value = value
        break

      default:
        break
    }

    if (this.value.startDate.value && this.value.endDate.value) {
      if (new Date(this.value.startDate.value).getTime() > new Date(this.value.endDate.value).getTime()) {
        this.dateError = 'reportSearchForm.dateError'
      } else {
        this.dateError = ''
      }
    }
  }

  async dateUpdatedWarning(): Promise<void> {
    this.toastHelper.toast.warning(await lastValueFrom(this.translate.get('reportSearchForm.dateMaxRangeUpdatedWarning')), {
      closeButton: true,
      position: 'top right',
    })
  }

  resetCategory(): CategoriesInterface {
    return {
      id: '',
      name: '',
      description: '',
      is_hidden: false,
      natura_trainings_category_id: ''
    }
  }

  resetForm (): void {
    this.selectedCategory = this.resetCategory()
    this.selectedSubcategory = this.resetCategory()
    this.sharedResetForm.emit()
  }
}
